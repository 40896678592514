import ElementUILocal from "element-ui/lib/locale/lang/de";

import country from "./country/de";
import state from "./state/de";

export default {
  message: {
    title: "Kasse",
    powerByOnerway: 'Angetrieben von <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Bitte schließen Sie die Zahlung innerhalb von <span class="time">{time}</span> Minuten ab, andernfalls kommt es zu einer Zeitüberschreitung der Seite.',
      billingAddressTip:
        "Die Gebühr erscheint auf Ihrer Rechnung als {billingAddress}.",
      cardInfo: "Karteninformationen",
      cardNumber: "Kartennummer",
      prepaidNumber: "Kartennummer",
      summary: "Bestellübersicht",
      expiredDate: "Verfallsdatum",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Vorname",
      lastName: "Nachname",
      holderName: "Name des Karteninhabers",
      walletAccountName: "Naam",
      orderno: "Bestellnummer",
      website: "Webseite",
      amount: "Höhe",
      pay: "Zahlen Sie",
      payNow: "Zahlen Sie jetzt",
      cancel: "Abbrechen",
      tip: "Tipps",
      cancelPayTip: "Möchten Sie auf diese Zahlung verzichten?",
      yes: "ja",
      no: "Nein",
      subscription: "Abonnement",
      schedule: "Zahlungsplan",
      endTime: "Endtermin",
      subscriptionTip:
        "Indem Sie Ihre Zahlung bestätigen, gestatten Sie dem Händler, Ihre Karte gemäß seinen Geschäftsbedingungen für zukünftige Zahlungen zu belasten.",
      frequencyData: {
        D: "Einmal alle {time} Tage",
      },
      endTimeData: "{month} {day}, {year}",
      billingAddress: "Rechnungsadresse/Kontaktinformationen",
      country: "Land",
      province: "Staat/Provinz/Region",
      address: "Adresse",
      city: "Ort/Stadt",
      postalCode: "Postleitzahl",
      email: "Email",
      phone: "Telefonnummer",
      street: "Hausnummer",
      number: "Nummer",
      identityNumber: "Steuer ID",
      intallment: "Inszenierung",
      intallmentPlan: "Aufbauplan",
      paymentMethod: "Zahlungsmethode",
      bankName: "Bank Name",
      iban: "IBAN",
      contactName: "Kontaktname",
      birthDate: "Geburtsdatum",
      paymentCompleted: "Bezahlvorgang abgeschlossen",
      wechatPaymentTip:
        "Bitte verwenden Sie WeChat, um den QR-Code zu scannen, um zu bezahlen",
      paymentPending: "Auf Zahlung warten",
      billingCountry: "Nation",
      billingCountryTip:
        "Bitte wählen Sie zuerst das Rechnungsland aus, damit wir Ihnen eine passende Zahlungsmethode empfehlen können.",
      nextStep: "Nächster schritt",
      changeCountryTip:
        "Nach dem Länderwechsel empfehlen wir Ihnen passende Zahlungsmethoden.",
      scanCode: "Scannen Sie es",
      txnTime: "Transaktionszeit",
      moreInfo: "Mehr informationen",
      applePayNotSupport: "Dieses Gerät unterstützt Apple Pay nicht.",
      policy: {
        prefix: "Ich habe die ",
        suffix: " gelesen und stimme ihr zu",
      },
      privacy: "Datenschutzrichtlinie",
      intallmentTip:
        "Es kann sein, dass Sie für die Ratenzahlungen Zinsen zahlen müssen.",
      payInFull: "Vollständig bezahlen",
      payIn: "Bezahlen in {count}",
      blikcode: "BLIK-Code",
      unavailable: "Nicht verfügbar",
      notSupportPaymentTips:
        "Die Zahlungsmethode ist momentan nicht verfügbar. Bitte kehren Sie zur Auswahlseite zurück.",
      returnText: "Zur Auswahl zurückkehren",
      returnMerchant: "Zurück zur Händlerseite",
      cvvTips:
        "Der CVC-Code befindet sich auf der Rückseite Ihrer Karte auf der rechten Seite des weißen Unterschriftsstreifens; es sind immer die letzten 3 Ziffern bei Visa und Mastercard. Bei American Express sind es 4 Ziffern auf der Vorderseite der Karte.",
      holderNameTips: "Vollständiger Name auf der Karte",
      continue: "Fortsetzen",
      validateCVVTitle: "Sicherheitsüberprüfung",
      validateCVVDesc:
        "Für Ihre Zahlungssicherheit, bestätigen Sie bitte den CVC/CVV Ihrer Karte",
    },
    dlocal: {
      amount: "Betrag",
      expirationDate: "zum Zeitpunkt",
      ticketNumber: "Ticketnummer",
      ticketImage: "Ticket Bild",
      downloadTicket: "Ticket herunterladen",
      searchForShops: "Suche nach Geschäften",
      fullBoletoView: "Volle Boleto-Ansicht",
      back: "BACK",
      copySuccess: "Kopieren",
      errorMsg: "Zeitüberschreitung. Fehler beim Abrufen der Parameter.",
      instructionsTitle: "Zahlungsanweisung",
      instructionsContentPIX:
        "ID Zahlungsanweisungen:<br/>1. ID der Replikationstransaktion<br/>2. Verwenden Sie die ID, um Ihre PIX-Zahlungen in Ihrer Banking-App zu identifizieren oder lesen Sie den QR-Code in der App der Bank.",
      instructionsContentRedpagos:
        "Sie müssen in eine redpagos-Filiale gehen, um die Zahlung vorzunehmen.<br/>Wenn Sie Ihre Ausweisnummer angeben und klarstellen, dass Sie eine dlocal-Rechnung bezahlen möchten, können Sie die Zahlung vornehmen.",
      instructionsContentOXXO:
        "1. Gehen Sie zur nächsten oxxo Filiale.<br/>2. Geben Sie in dem Feld an, dass Sie eine oxxopay-Zahlung vornehmen möchten.<br/>3. Diktiert der Kassiererin oder dem Kassierer die Referenznummer auf dieser Karte zum Eintippen<br/>Direkt auf dem Verkaufsbildschirm.<br/>4. Führt die entsprechende Zahlung mit Bargeld durch.<br/>5. Wenn Sie Ihre Zahlung bestätigen, gibt Ihnen die Kassiererin einen ausgedruckten Beleg. Hier können Sie überprüfen, ob die Zahlung korrekt erfolgt ist. Bewahren Sie diesen Zahlungsnachweis auf.<br/>6. Für weitere Informationen zu Ihrer Zahlung besuchen Sie unser Help Center",
      instructionsContentSPEI:
        "1. Sofortige Akkreditierung.<br/>2. Sobald die Überweisung eingegangen ist, informiert dlocal den Lieferanten über das Produkt/die Dienstleistung, die Sie kaufen möchten.<br/>3. Sollten Sie Probleme mit der Zahlung haben, besuchen Sie bitte unser Kundenserviceportal.",
      instructionsContentSencillito:
        'Persönliche Zahlungsanweisung:<br/>In der Geschäftsstelle von Sencillito können Sie eine Zahlung vornehmen, indem Sie eine Referenznummer angeben und deutlich machen, dass Sie die Rechnung von DLOCAL bezahlen möchten.<br/>Online-Zahlungsanweisung:<br/>Um die Zahlung vorzunehmen, müssen Sie das Portal Sencillito aufrufen, in der Suchmaschine des Unternehmens "dlocal" auswählen und den auf dem Beleg 134216 angegebenen Code eingeben."',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Bankverbindung",
      bankDetailsSPEI:
        'Melden Sie sich in Ihrem Online-Banking an und überweisen Sie auf das nächste Konto. Bitte beachten Sie, dass eine Barauszahlung nicht möglich ist.<br/>Ziel-Bank:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Begünstigter: <span class="detail-value">{beneficiary}</span><br/>Betrag: <span class="detail-value">{amount} {currency}</span><br/>Zahlungskonzept: <span class="detail-value">{concept}</span><br/>Referenz: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Art der Zahlung",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Betrag",
      orderTips: "Bitte überweisen Sie auf das folgende Konto",
      numberTitle: "Kontonummer (nur für diese Transaktion)",
      copyBtnText: "Kontonummer kopieren",
      expireText: "Bestellung läuft ab in {time}",
      bankName: "Bankname",
    },
    date: {
      month: {
        1: "Januar",
        2: "Februar",
        3: "Marsch",
        4: "April",
        5: "Kann",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
    },
    result: {
      success: "Bezahlung erfolgreich",
      failed: "Bezahlung fehlgeschlagen",
      unknow: "Bezahlen",
      unknowTip:
        "Bitte aktualisieren Sie den Bestellstatus, um das Ergebnis zu sehen.",
      jump: 'Rückkehr zum Händler in <span class="hightlight">{time}s</span>',
      reason: "Grund",
      processing: "Wird bearbeitet",
      timeout:
        "Die Seite ist abgelaufen, bitte starten Sie die Zahlung erneut.",
      invalid: "Ungültige Transaktionsanforderung",
      cancelled:
        "Die Zahlungsanwendung wurde storniert,<br/>bitte wiederholen Sie die Zahlung nicht.",
      emptyPaymentMethod:
        "Für dieses Land sind keine Zahlungsmethoden konfiguriert.",
      error:
        "Fehler. Bitte überprüfen Sie das Transaktionsergebnis auf der Bestellseite der Shopping-Website.",
    },
    validator: {
      input: "Bitte geben Sie einen gültigen {field} ein",
      intallmentCardOrCountryNotSupport:
        "Ratenzahlung wird vom Kartentyp oder Land nicht unterstützt",
    },
    error: {
      timeout: "Netzwerkfehler, bitte versuchen Sie es später erneut.",
      cardNumber: "Karteninformationen sind falsch",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
