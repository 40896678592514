import ElementUILocal from "element-ui/lib/locale/lang/nb-NO";

import country from "./country/no";
import state from "./state/no";

export default {
  message: {
    title: "Kasse",
    powerByOnerway: 'Drevet av <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Vennligst fullfør betalingen innen <span class="time">{time}</span> minutter, ellers vil siden tidsavbrytes',
      billingAddressTip:
        "Belastningen vil vises på regningen din som {billingAddress}",
      cardInfo: "Kortinformasjon",
      cardNumber: "Kortnummer",
      prepaidNumber: "Kortnummer",
      summary: "Ordre summary",
      expiredDate: "Gyldighetsperiode",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Fornavn",
      lastName: "Etternavn",
      holderName: "Kortholders navn",
      walletAccountName: "Navn",
      orderno: "Bestillings-ID",
      website: "Nettsideadresse",
      amount: "Beløp",
      pay: "Betala",
      payNow: "Betal nå",
      cancel: "Avbry",
      tip: "Tips",
      cancelPayTip: "Vil du kansellere denne betalingen?",
      yes: "Ja",
      no: "Nei",
      subscription: "Abonnement",
      schedule: "Betalingsperiode",
      endTime: "Sluttid",
      subscriptionTip:
        "Etter at du har bekreftet betalingen, lar du selgeren debitere kortet ditt i henhold til vilkårene i fremtiden.",
      frequencyData: {
        D: "{time}dag/tid",
      },
      endTimeData: "{year}år{month}måned{day}dag",
      billingAddress: "Faktureringsadresse/Kontaktinfo",
      country: "Land",
      province: "Stat/Provins/Region",
      address: "Adresse",
      city: "By",
      postalCode: "Postnummer",
      email: "E-post",
      phone: "Telefonnummer",
      street: "Gatenummer",
      number: "Husnumme",
      identityNumber: "Skattenummer",
      intallment: "Iscenesettelse",
      intallmentPlan: "Avbetalingsplan",
      paymentMethod: "Betalingsmetode",
      bankName: "Banknavn",
      iBan: "Bankkontonummer",
      contactName: "Kontaktnavn",
      birthDate: "Fødselsdato",
      paymentCompleted: "Betaling fullført",
      wechatPaymentTip: "Bruk WeChat for å skanne QR-koden for å betale",
      paymentPending: "Vente på betaling",
      billingCountry: "Faktureringsland",
      billingCountryTip:
        "Velg faktureringsland først, slik at vi kan anbefale en passende betalingsmetode for deg.",
      nextStep: "Neste steg",
      changeCountryTip:
        "Etter å ha byttet land, vil vi anbefale passende betalingsmåter til deg.",
      scanCode: "Skann den",
      txnTime: "Transaksjonstid",
      moreInfo: "Mer informasjon",
      applePayNotSupport: "Denne enheten støtter ikke Apple Pay.",
      policy: {
        prefix: "Jeg har lest og godtar ",
        suffix: "",
      },
      privacy: "personvernreglene",
      intallmentTip: "Det kan hende du må betale renter på avdragene.",
      payInFull: "Betale i sin helhet",
      payIn: "Betal i {count}",
      blikcode: "BLIK-kode",
      unavailable: "Ikke tilgjengelig",
      notSupportPaymentTips:
        "Betalingsmetoden er ikke tilgjengelig for øyeblikket. Vennligst gå tilbake til valgsiden.",
      returnText: "Gå tilbake til valg",
      returnMerchant: "Tilbake til forhandlerens side",
      cvvTips:
        "CVC-koden er plassert på baksiden av kortet ditt på høyre side av den hvite signaturstripen; det er alltid de siste 3 sifrene for Visa og Mastercard. For American Express er det 4 sifre på forsiden av kortet.",
      holderNameTips: "Fullt navn på kortet",
      continue: "Fortsette",
      validateCVVTitle: "Sikkerhetsbekreftelse",
      validateCVVDesc:
        "For din betalings sikkerhet, vennligst bekreft din korts CVC/CVV",
    },
    dlocal: {
      amount: "beløp",
      expirationDate: "Avskjæringsdato",
      ticketNumber: "Billettnummer",
      ticketImage: "Billettbilde",
      downloadTicket: "Last ned billett",
      searchForShops: "Søk etter butikker",
      fullBoletoView: "Full Boleto-visning",
      back: "retur",
      copySuccess: "Kopier",
      errorMsg: "Tidsavbrudd. Kan ikke hente parametere.",
      instructionsTitle: "Betalingsinstruksjon",
      instructionsContentPIX:
        "Instruksjoner for ID-betaling:<br/>1. Transaksjons-ID for replikasjon<br/>2. Bruk ID-en til å identifisere PIX-betalingene dine i bankappen din, eller les QR-koden i bankens app.",
      instructionsContentRedpagos:
        "Du må gå til en redpagos-butikk for å utføre betalingen.<br/>Ved å si ID-kortnummeret ditt og presisere at du ønsker å betale en dlocal-regning, vil du kunne utføre betalingen.",
      instructionsContentOXXO:
        "1. Gå til nærmeste oxxo-butikk.<br/>2. Angi i boksen at du ønsker å foreta en oxxopay-betaling.<br/>3. Dikter referansenummeret på dette kortet til kassereren for å skrive<br/>direkte på salgsskjermen.<br/>4. Foreta tilsvarende betaling med kontanter.<br/>5. Når du bekrefter betalingen, får du en utskrevet kupong av kassapersonalet. Her kan du bekrefte at det har gått riktig for seg. Ta vare på dette betalingsbeviset.<br/>6. For mer informasjon om betalingen din, besøk vårt hjelpesenter",
      instructionsContentSPEI:
        "1. Umiddelbar akkreditering.<br/>2. Når bankoverføringen er mottatt, vil dlocal informere leverandøren om produktet/tjenesten du kjøper.<br/>3. Hvis du har noen betalingsproblemer, vennligst besøk vår kundeserviceportal.",
      instructionsContentSencillito:
        'Betalingsinstruksjon personlig:<br/>På Sencillitos forretningssted kan du foreta en betaling ved å oppgi et referansenummer og gjøre det klart at du vil betale den lokale fakturaen.<br/>Online betalingsinstruksjon:<br/>For å foreta betalingen må du angi Portal Sencillito, velge "dlocal" i selskapets søkemotor og skrive inn koden som er angitt på kupongen 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Bankopplysninger",
      bankDetailsSPEI:
        'Logg inn på nettbanken og overfør til neste konto. Vær oppmerksom på at kontantbetalinger ikke er mulig.<br/>Målbank:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Mottaker: <span class="detail-value">{beneficiary}</span><br/>Beløp: <span class="detail-value">{amount} {currency}</span><br/>Betalingskonsept: <span class="detail-value">{concept}</span><br/>Referanse: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Type betaling",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Beløp",
      orderTips: "Vennligst overfør til følgende konto",
      numberTitle: "Kontonummer (kun for denne transaksjonen)",
      copyBtnText: "Kopier kontonummer",
      expireText: "Bestilling utløper om {time}",
      bankName: "Banknavn",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "Betaling vellykket",
      failed: "Betaling mislyktes",
      unknow: "Betaler",
      unknowTip:
        "Vennligst oppdater ordrestatusen for å sjekke betalingsresultatet",
      jump: 'Går tilbake til forhandleren om <span class="hightlight">{time}s</span>',
      reason: "Årsaken",
      processing: "Behandler...",
      timeout: "Siden blir tidsavbrutt, vennligst start betalingen på nytt",
      invalid: "Ugyldig transaksjonsforespørsel",
      cancelled:
        "Betalingssøknaden er kansellert, vennligst ikke gjenta betalingen",
      emptyPaymentMethod:
        "Det er ingen betalingsmåter konfigurert for dette landet.",
      error:
        "Feil. Vennligst sjekk transaksjonsresultatet på bestillingssiden til shoppingnettstedet.",
    },
    validator: {
      input: "Vennligst skriv inn et gyldig {field}",
      intallmentCardOrCountryNotSupport:
        "Avdragsbetaling støttes ikke av korttype eller land!",
    },
    error: {
      timeout: "Nettverket er unormalt, prøv igjen senere",
      cardNumber: "Kortinformasjonen er feil",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
