import zhLocale from "element-ui/lib/locale/lang/th";

import country from "./country/th";
import state from "./state/th";

export default {
  message: {
    title: "เคาน์เตอร์ชำระเงิน",
    powerByOnerway: 'ขับเคลื่อนโดย <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'กรุณาชำระเงินให้เรียบร้อยภายใน <span class="time">{time}</span> นาที มิฉะนั้น เพจจะหมดเวลา',
      billingAddressTip:
        "ค่าใช้จ่ายจะปรากฏในใบเรียกเก็บเงินของคุณเป็น {billingAddress}",
      cardInfo: "ข้อมูลบัตร",
      cardNumber: "หมายเลขบัตร",
      prepaidNumber: "หมายเลขบัตร",
      summary: "สรุปคำสั่งซื้อ",
      expiredDate: "วันหมดอายุ",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "ชื่อ",
      lastName: "นามสกุล",
      holderName: "ชื่อผู้ถือบัตร",
      walletAccountName: "ชื่อ",
      orderno: "หมายเลขคำสั่งซื้อ",
      website: "URL",
      amount: "จำนวน",
      pay: "จ่าย",
      payNow: "จ่ายทันที",
      cancel: "ยกเลิก",
      tip: "คำใบ้",
      cancelPayTip: "คุณต้องการละเว้นการชำระเงินนี้หรือไม่?",
      yes: "ใช่",
      no: "ไม่",
      subscription: "สมัครสมาชิก",
      schedule: "ระยะเวลาการชำระเงิน",
      endTime: "เส้นตาย",
      subscriptionTip:
        "โดยการยืนยันการชำระเงิน คุณอนุญาตให้ผู้ค้าหักบัตรของคุณในอนาคตตามเงื่อนไข",
      frequencyData: {
        D: "{time}วัน/เวลา",
      },
      endTimeData: "{year}ปี {month}เดือน{day}วัน",
      billingAddress: "ที่อยู่สำหรับการเรียกเก็บเงิน/ข้อมูลติดต่อ",
      country: "ชาติ",
      province: "รัฐ/จังหวัด/ภูมิภาค",
      address: "ที่อยู่",
      city: "เมือง",
      postalCode: "รหัสไปรษณีย์",
      email: "จดหมาย",
      phone: "หมายเลขโทรศัพท์",
      street: "เลขที่ถนน",
      number: "บ้านเลขที่",
      identityNumber: "หมายเลขภาษี",
      intallment: "จัดฉาก",
      intallmentPlan: "แผนผ่อนชำระ",
      paymentMethod: "วิธีการชำระเงิน",
      bankName: "ชื่อธนาคาร",
      iBan: "บัญชีธนาคาร",
      contactName: "ชื่อผู้ติดต่อ",
      birthDate: "วันเกิด",
      paymentCompleted: "ชำระเงินเรียบร้อยแล้ว",
      wechatPaymentTip: "โปรดใช้ WeChat เพื่อสแกนรหัส QR เพื่อชำระเงิน",
      paymentPending: "รอการชำระเงิน",
      billingCountry: "ประเทศที่เรียกเก็บเงิน",
      billingCountryTip:
        "โปรดเลือกประเทศสำหรับการเรียกเก็บเงินก่อน เพื่อที่เราจะสามารถแนะนำวิธีการชำระเงินที่เหมาะสมกับคุณได้",
      nextStep: "ขั้นตอนต่อไป",
      changeCountryTip:
        "หลังจากเปลี่ยนประเทศ เราจะแนะนำวิธีการชำระเงินที่เหมาะสมให้กับคุณ",
      scanCode: "สแกนมัน",
      txnTime: "เวลาในการทำธุรกรรม",
      moreInfo: "ข้อมูลมากกว่านี้",
      applePayNotSupport: "อุปกรณ์นี้ไม่รองรับ Apple Pay",
      policy: {
        prefix: "ฉันได้อ่านและยอมรั",
        suffix: "",
      },
      privacy: "บนโยบายความเป็นส่วนตัวแล้ว",
      intallmentTip: "คุณอาจต้องจ่ายดอกเบี้ยเป็นงวด",
      payInFull: "ชำระเงินเต็มจำนวน",
      payIn: "จ่ายใน{count}",
      blikcode: "รหัส BLIK",
      unavailable: "ไม่พร้อมใช้งาน",
      notSupportPaymentTips:
        "วิธีการชำระเงินไม่พร้อมใช้งานในขณะนี้ กรุณากลับไปที่หน้าการเลือก",
      returnText: "กลับไปที่การเลือก",
      returnMerchant: "กลับไป ที่หน้าธุรกิจ",
      cvvTips:
        "รหัส CVC จะอยู่ที่ด้านหลังของบัตรทางด้านขวาของแถบลายเซ็นสีขาว; มักเป็นตัวเลข 3 หลักสุดท้ายของบัตร Visa และ Mastercard สำหรับ American Express จะเป็นตัวเลข 4 หลักที่ด้านหน้าของบัตร",
      holderNameTips: "ชื่อเต็มบนบัตร",
      continue: "ดำเนินการต่อ",
      validateCVVTitle: "การยืนยันความปลอดภัย",
      validateCVVDesc:
        "เพื่อความปลอดภัยในการชำระเงินของคุณ กรุณายืนยัน CVC/CVV ของบัตร",
    },
    dlocal: {
      amount: "จำนวนเงิน",
      expirationDate: "วันครบกำหนด",
      ticketNumber: "หมายเลข",
      ticketImage: "ภาพไอคอนเท็กซ์",
      downloadTicket: "ดาวน์โหลด",
      searchForShops: "ค้นหาร้านค้า",
      fullBoletoView: "มุมมอง Boleto แบบเต็ม",
      back: "กลับมา",
      copySuccess: "คัดลอก",
      errorMsg: "หมดเวลา. ล้มเหลวในการรับพารามิเตอร์",
      instructionsTitle: "คำแนะนำในการชำระเงิน",
      instructionsContentPIX:
        "คําแนะนําการชําระเงิน ID:<br/>1. รหัสธุรกรรมการจําลองแบบ<br/>2. ใช้ ID เพื่อระบุการชําระเงิน PIX ของคุณในแอปธนาคารหรืออ่านรหัส QR ในแอปของธนาคาร",
      instructionsContentRedpagos:
        "คุณจะต้องไปที่ร้าน redpagos เพื่อชําระเงิน<br/>โดยบอกหมายเลขบัตรประชาชนของคุณและชี้แจงว่าคุณต้องการชําระบิล dlocal คุณจะสามารถชําระเงินได้",
      instructionsContentOXXO:
        "1. ไปที่ร้าน oxxo ที่ใกล้ที่สุด<br/>2. ระบุในช่องที่คุณต้องการชําระเงิน oxxopay<br/>3. กําหนดหมายเลขอ้างอิงบนบัตรนี้ให้กับแคชเชียร์เพื่อพิมพ์<br/>โดยตรงบนหน้าจอการขาย<br/>4. ชําระเงินด้วยเงินสด<br/>5. เมื่อคุณยืนยันการชําระเงินแคชเชียร์จะให้บัตรกํานัลที่พิมพ์ออกมา ที่นี่คุณสามารถตรวจสอบได้ว่าทําอย่างถูกต้อง เก็บหลักฐานการชําระเงินนี้ไว้<br/>6. สําหรับข้อมูลเพิ่มเติมเกี่ยวกับการชําระเงินของคุณโปรดไปที่ศูนย์ช่วยเหลือของเรา",
      instructionsContentSPEI:
        "1. การรับรองทันที<br/>2. เมื่อได้รับการโอนเงินผ่านธนาคารแล้ว dlocal จะแจ้งการส่งมอบผลิตภัณฑ์ / บริการที่คุณกําลังซื้อ<br/>3. หากคุณมีปัญหาการชําระเงินใด ๆ โปรดไปที่พอร์ทัลบริการลูกค้าของเรา",
      instructionsContentSencillito:
        "การก่อสร้างในบุคคล:<br/>ที่สถาน ที่สำหรับธุรกิจและทำให้มันชัดเจนว่า คุณต้องการ ที่จะเปิดเสียงท้องถิ่น<br/>การก่อสร้างทางออนไลน์<br/>ที่จะทำให้คุณต้องเปิดประตูมิติในการค้นหา บริษัท และนำรหัสลงในบัตร 134216",
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "รายละเอียดธนาคาร:",
      bankDetailsSPEI:
        'เข้าสู่ระบบธนาคารออนไลน์ของคุณและโอนไปยังบัญชีถัดไป โปรดทราบว่า ท่านไม่สามารถชําระด้วยเงินสดได้<br/>ธนาคารปลายทาง:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>ผู้รับผลประโยชน์: <span class="detail-value">{beneficiary}</span><br/>จํานวนเงิน: <span class="detail-value">{amount} {currency}</span><br/>แนวคิดการชําระเงิน: <span class="detail-value">{concept}</span><br/>อ้างอิง: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "ประเภทการชําระเงิน",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "จำนวนเงิน",
      orderTips: "โปรดโอนเงินเข้าบัญชีต่อไปนี้",
      numberTitle: "หมายเลขบัญชี (สำหรับธุรกรรมนี้เท่านั้น)",
      copyBtnText: "คัดลอกหมายเลขบัญชี",
      expireText: "คำสั่งซื้อลงใน {time}",
      bankName: "ชื่อธนาคาร",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "ชำระเงินสำเร็จ",
      failed: "การชำระเงินล้มเหลว",
      unknow: "การชำระเงิน",
      unknowTip: "โปรดรีเฟรชสถานะการสั่งซื้อเพื่อตรวจสอบผลการชำระเงิน",
      jump: 'กำลังกลับไปยังผู้ค้าใน <span class="hightlight">{time}s</span>',
      reason: "เหตุผล",
      processing: "กำลังประมวลผล....",
      timeout: "หน้าหมดเวลา โปรดเริ่มต้นการชำระเงินใหม่",
      invalid: "คำขอธุรกรรมที่ไม่ถูกต้อง",
      cancelled: "แอปพลิเคชันการชำระเงินถูกยกเลิก โปรดอย่าชำระเงินอีกครั้ง",
      emptyPaymentMethod: "ไม่มีการกำหนดค่าวิธีการชำระเงินสำหรับประเทศนี้",
      error:
        "ข้อผิดพลาด. กรุณาตรวจสอบผลการทำธุรกรรมที่หน้าการสั่งซื้อของเว็บไซต์ช้อปปิ้ง",
    },
    validator: {
      input: "โปรดป้อน {field}ที่ถูกต้อง",
      intallmentCardOrCountryNotSupport:
        "ไม่รองรับการผ่อนชำระตามประเภทบัตรหรือประเทศ",
    },
    error: {
      timeout: "เครือข่ายผิดพลาด โปรดลองอีกครั้งในภายหลัง",
      cardNumber: "ข้อมูลบัตรไม่ถูกต้อง",
    },
    country,
    state,
  },
  ...zhLocale,
};
