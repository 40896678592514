import zhLocale from "element-ui/lib/locale/lang/ru-RU";

import country from "./country/ru";
import state from "./state/ru";

export default {
  message: {
    title: "Касса",
    powerByOnerway: 'На базе <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Пожалуйста, завершите платеж в течение  <span class="time">{time}</span> минут, иначе страница будет закрыта по тайм-ауту.',
      billingAddressTip:
        "Расходы будут отображаться в вашем счете как {billingAddress}",
      cardInfo: "информация о карте",
      cardNumber: "номер карты",
      prepaidNumber: "номер карты",
      summary: "итог заказа",
      expiredDate: "Срок годности",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "название",
      lastName: "фамилия",
      holderName: "Имя владельца карты",
      walletAccountName: "Имя",
      orderno: "ID заказа",
      website: "URL-адрес",
      amount: "количество",
      pay: "Платить",
      payNow: "платить немедленно",
      cancel: "Отмена",
      tip: "намекать",
      cancelPayTip: "Вы хотите отказаться от этого платежа??",
      yes: "Да",
      no: "нет",
      subscription: "подписка",
      schedule: "Период оплаты",
      endTime: "срок",
      subscriptionTip:
        "Подтверждая платеж, вы разрешаете продавцу списывать средства с вашей карты в будущем в соответствии с условиями. ",
      frequencyData: {
        D: "{time}день/время",
      },
      endTimeData: "{year}год{month}Луна{day}Нет",
      billingAddress: "Платежный адрес/контактная информация",
      country: "нация",
      province: "Штат/Провинция/Регион",
      address: "адрес",
      city: "Город",
      postalCode: "Почтовый индекс",
      email: "Почта",
      phone: "номер телефона",
      street: "номер улицы",
      number: "Номер дома",
      identityNumber: "налоговый номер",
      intallment: "Постановка",
      intallmentPlan: "Рассрочка",
      paymentMethod: "способ оплаты",
      bankName: "Название банка",
      iBan: "Банковский счет",
      contactName: "Контактное лицо",
      birthDate: "Дата рождения",
      paymentCompleted: "Платеж завершен",
      wechatPaymentTip:
        "Пожалуйста, используйте WeChat для сканирования QR-кода для оплаты",
      paymentPending: "ждать оплаты",
      billingCountry: "Cтраной плательщика",
      billingCountryTip:
        "Сначала выберите страну выставления счета, чтобы мы могли порекомендовать вам подходящий способ оплаты.",
      nextStep: "Следующий шаг",
      changeCountryTip:
        "После переключения стран мы порекомендуем вам подходящие способы оплаты.",
      scanCode: "Отсканируй это",
      txnTime: "Время транзакции",
      moreInfo: "Дополнительная информация",
      applePayNotSupport: "Это устройство не поддерживает Apple Pay.",
      policy: {
        prefix: "Я прочитал и согласен с ",
        suffix: "",
      },
      privacy: "Политикой конфиденциальности",
      intallmentTip:
        "Возможно, вам придется выплачивать проценты за рассрочку.",
      payInFull: "Оплатить полностью",
      payIn: "Оплата через {count}",
      blikcode: "Код BLIK",
      unavailable: "Недоступно",
      notSupportPaymentTips:
        "Способ оплаты в данный момент недоступен. Пожалуйста, вернитесь на страницу выбора.",
      returnText: "Вернуться к выбору",
      returnMerchant: "Вернуться на страницу продавца",
      cvvTips:
        "Код CVC находится на обратной стороне вашей карты с правой стороны белой полосы для подписи; это всегда последние 3 цифры для Visa и Mastercard. Для American Express это 4 цифры на лицевой стороне карты.",
      holderNameTips: "Полное имя на карте",
      continue: "Продолжить",
      validateCVVTitle: "Проверка безопасности",
      validateCVVDesc:
        "Для безопасности вашей оплаты, пожалуйста, подтвердите CVC/CVV вашей карты",
    },
    dlocal: {
      amount: "сумм",
      expirationDate: "Крайний срок",
      ticketNumber: "Ticket номер",
      ticketImage: "Изображение билета",
      downloadTicket: "Билет для скачивания",
      searchForShops: "поиск магазинов",
      fullBoletoView: "Полный вид болето",
      back: "назад",
      copySuccess: "Копировать",
      errorMsg: "Превышено время ожидания. Не удалось получить параметры.",
      instructionsTitle: "Инструкция по оплате",
      instructionsContentPIX:
        "ID Инструкции по оплате:<br/>1. Идентификатор транзакции репликации<br/>2. Используйте ID для идентификации платежей PIX в вашем банковском приложении или считайте QR-код в приложении банка.",
      instructionsContentRedpagos:
        "Чтобы совершить платеж, вам нужно будет прийти в магазин Redpagos.<br/>Назвав номер своей идентификационной карты и уточнив, что вы хотите оплатить счет dlocal, вы сможете совершить платеж.",
      instructionsContentOXXO:
        "1. Отправляйтесь в ближайший магазин oxxo.<br/>2. Укажите в поле, что вы хотите совершить платеж oxxopay.<br/>3. Продиктуйте номер карты кассиру для ввода.<br/>непосредственно на экране продаж.<br/>4. Произведите соответствующий платеж наличными.<br/>5. Когда вы подтвердите оплату, кассир выдаст вам распечатанный ваучер. Здесь вы можете убедиться, что все было сделано правильно. Сохраните это доказательство оплаты.<br/>6. Для получения дополнительной информации о вашем платеже посетите наш справочный центр",
      instructionsContentSPEI:
        "1. Немедленная аккредитация.<br/>2. После получения банковского перевода dlocal сообщит о доставке приобретаемого продукта/услуги.<br/>3. Если у вас возникли проблемы с оплатой, пожалуйста, посетите наш портал обслуживания клиентов.",
      instructionsContentSencillito:
        'Платежная инструкция лично:<br/>В офисе Sencillito вы можете произвести оплату, указав номер и указав, что хотите оплатить счет-фактуру DLOCAL.<br/>Платежная инструкция онлайн:<br/>Для оплаты необходимо зайти на портал Sencillito, выбрать "dlocal"в поисковой системе компании и ввести код, указанный на ваучере 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Банковские реквизиты",
      bankDetailsSPEI:
        'Войдите в систему и переведите деньги на следующий счет. Обратите внимание, что оплата наличными не возможна.<br/>Банк назначения:<span class="detail-value">{bank}</span><br/Телефон: <span class="detail-value">{clabe}</span><br/>Бенефициар: <span class="detail-value">{beneficiary}</span><br/>Сумма: <span class="detail-value">{amount} {currency}</span><br/>Концепция оплаты: <span class="detail-value">{concept}</span><br/>Справочный документ: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Тип оплаты",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Сумма",
      orderTips: "Пожалуйста, переведите на следующий счет",
      numberTitle: "Номер счета (только для этой транзакции)",
      copyBtnText: "Скопировать номер счета",
      expireText: "Заказ истекает через {time}",
      bankName: "Название банка",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "оплата прошла успешно",
      failed: "Платеж не прошел",
      unknow: "Платежи",
      unknowTip:
        "Пожалуйста, обновите статус заказа, чтобы проверить результат платежа",
      jump: 'Возвращение к продавцу через <span class="hightlight">{time}s</span>',
      reason: "причина",
      processing: "Обработка....",
      timeout: "Время ожидания страницы истекло, повторите платеж",
      invalid: "Неверный запрос на транзакцию",
      cancelled: "Заявка на оплату была отменена, пожалуйста, не платите снова",
      emptyPaymentMethod: "Для этой страны не настроены способы оплаты.",
      error:
        "Ошибка. Пожалуйста, проверьте результат транзакции на странице заказа торгового сайта.",
    },
    validator: {
      input: "Пожалуйста, введите допустимый {field}",
      intallmentCardOrCountryNotSupport:
        "Оплата в рассрочку не поддерживается типом карты или страной",
    },
    error: {
      timeout: "Ошибка сети, повторите попытку позже",
      cardNumber: "Информация о карте неверна",
    },
    country,
    state,
  },
  ...zhLocale,
};
