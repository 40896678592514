import ElementUILocal from "element-ui/lib/locale/lang/pt";

import country from "./country/pt";
import state from "./state/pt";

export default {
  message: {
    title: "Caixa",
    powerByOnerway: 'Movido por <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Por favor, complete o pagamento dentro de <span class="time">{time}</span> minutos, caso contrário a página irá expirar',
      billingAddressTip:
        "A cobrança aparecerá em sua fatura como {billingAddress}",
      cardInfo: "Informações do cartão",
      cardNumber: "Número do cartão",
      prepaidNumber: "Número do cartão",
      summary: "Resumo do pedido",
      expiredDate: "Período de validade",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Primeiro nome",
      lastName: "Sobrenome",
      holderName: "Nome do Titular",
      walletAccountName: "Designação",
      orderno: "ID do pedido",
      website: "Local na rede Internet",
      amount: "Quantia",
      pay: "Pagar",
      payNow: "Pague agora",
      cancel: "Cancelar",
      tip: "Dica",
      cancelPayTip: "Deseja cancelar este pagamento?",
      yes: "Sim",
      no: "Não",
      subscription: "Assinatura",
      schedule: "Período de pagamento",
      endTime: "Prazo",
      subscriptionTip:
        "Após confirmar o pagamento, você permite que o comerciante debite seu cartão de acordo com os termos no futuro",
      frequencyData: {
        D: "{time}dia/hora",
      },
      endTimeData: "{year}ano{month}mês{day}dia",
      billingAddress: "Endereço de cobrança/informações de contato",
      country: "País",
      province: "Estado/Província/Região",
      address: "Endereço",
      city: "Cidade",
      postalCode: "Código Postal",
      email: "E-mail",
      phone: "Número de telefone",
      street: "Número da rua",
      number: "Número da casa",
      identityNumber: "Número fiscal",
      intallment: "Parcela",
      intallmentPlan: "Plano de parcelamento",
      paymentMethod: "Forma de pagamento",
      bankName: "Nome do banco",
      iBan: "Número da conta bancária",
      contactName: "Nome do contato",
      birthDate: "Data de nascimento",
      paymentCompleted: "Pagamento concluído",
      wechatPaymentTip:
        "Por favor, use WeChat para escanear o código QR para pagar",
      paymentPending: "Aguarde o pagamento",
      billingCountry: "País de faturamento",
      billingCountryTip:
        "Selecione primeiro o país de cobrança para que possamos recomendar um método de pagamento adequado para você.",
      nextStep: "Próxima etapa",
      changeCountryTip:
        "Depois de mudar de país, recomendamos métodos de pagamento adequados para você.",
      scanCode: "Escaneie",
      txnTime: "Tempo de transação",
      moreInfo: "Mais Informações",
      applePayNotSupport: "Este dispositivo não oferece suporte ao Apple Pay.",
      policy: {
        prefix: "Li e concordo com a ",
        suffix: "",
      },
      privacy: "Política de Privacidade",
      intallmentTip: "Você pode ter que pagar juros sobre as parcelas.",
      payInFull: "Pagar integralmente",
      payIn: "Pagamento em {count}",
      blikcode: "Código BLIK",
      unavailable: "Indisponível",
      notSupportPaymentTips:
        "O método de pagamento não está disponível no momento. Por favor, volte para a página de seleção.",
      returnText: "Voltar para seleção",
      returnMerchant: "Voltar à página do comerciante",
      cvvTips:
        "O código CVC está localizado no verso do seu cartão, no lado direito da faixa branca de assinatura; são sempre os últimos 3 dígitos para Visa e Mastercard. Para American Express, são 4 dígitos na parte frontal do cartão.",
      holderNameTips: "Nome completo no cartão",
      continue: "Continuar",
      validateCVVTitle: "Verificação de segurança",
      validateCVVDesc:
        "Para sua segurança de pagamento, verifique o CVC/CVV do seu cartão",
    },
    dlocal: {
      amount: "Montante",
      expirationDate: "Prazo de validade",
      ticketNumber: "Número do bilhete",
      ticketImage: "Imagem do bilhete",
      downloadTicket: "Baixar ticket",
      searchForShops: "Pesquisa de lojas",
      fullBoletoView: "Vista completa do boleto",
      back: "VOLTAR",
      copySuccess: "Copiar",
      errorMsg: "Tempo esgotado. Falha ao obter os parâmetros.",
      instructionsTitle: "Instrução de pagamento",
      instructionsContentPIX:
        "1. Copie o ID da transação<br/>2. Utilize o ID para identificar os seus pagamentos PIX na sua aplicação bancária ou leia o código QR na aplicação do banco.",
      instructionsContentRedpagos:
        "Terá de se dirigir a uma loja redpagos para efetuar o pagamento.<br/>Ao dizer o número do seu cartão de identificação e esclarecer que pretende pagar uma fatura dlocal, poderá efetuar o pagamento.",
      instructionsContentOXXO:
        "1. Dirija-se à loja do Oxxo mais próxima.<br/>2. Indique na caixa que pretende efetuar um pagamento oxxopay.<br/>3. Dita o número de referência deste cartão ao empregado da caixa para que este o digite<br/>Diretamente no ecrã de venda.<br/>4. Efetuar o pagamento correspondente em dinheiro.<br/>5. Quando confirmar o pagamento, o caixa entregar-lhe-á um talão impresso. Aqui pode verificar se o pagamento foi efectuado corretamente. Guarde este comprovativo de pagamento.<br/>6. Para mais informações sobre o seu pagamento, visite o nosso centro de ajuda",
      instructionsContentSPEI:
        "1. Acreditação imediata.<br/>2. Assim que a transferência bancária for recebida, a dlocal informará a entrega do produto/serviço que está a adquirir.<br/>3. Se tiver algum problema com o pagamento, visite o nosso portal de apoio ao cliente.",
      instructionsContentSencillito:
        'Instruções de pagamento presencial:<br/>No local de negócios de Sencillito, você pode fazer um pagamento fornecendo um número de referência e deixando claro que deseja pagar a fatura DLOCAL.<br/>Instrução de pagamento online:<br/>Para efetuar o pagamento é preciso entrar no Portal Sencillito, escolher "dlocal" no buscador da empresa e digitar o código indicado no 134216 do voucher.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Dados bancários",
      bankDetailsSPEI:
        'Faça login no seu banco online e transfira para a próxima conta.  Por favor, note que os pagamentos em dinheiro não são possíveis.<br/>Banco de destino:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Beneficiário: <span class="detail-value">{beneficiary}</span><br/>Valor: <span class="detail-value">{amount} {currency}</span><br/>Conceito de pagamento: <span class="detail-value">{concept}</span><br/>Referência: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Tipo de pagamento",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Quantia",
      orderTips: "Por favor, transfira para a seguinte conta",
      numberTitle: "Número da conta (somente para esta transação)",
      copyBtnText: "Copiar número da conta",
      expireText: "Pedido expira em {time}",
      bankName: "Nome do banco",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "Pagamento bem-sucedido",
      failed: "Falha no pagamento",
      unknow: "Pagando",
      unknowTip:
        "Atualize o status do pedido para verificar o resultado do pagamento",
      jump: 'Retornando ao comerciante em <span class="hightlight">{time}s</span>',
      reason: "Motivo",
      processing: "Processando...",
      timeout: "A página expira, por favor reinicie o pagamento",
      invalid: "Solicitação de transação inválida",
      cancelled:
        "O pedido de pagamento foi cancelado, por favor, não repita o pagamento",
      emptyPaymentMethod:
        "Não há métodos de pagamento configurados para este país.",
      error:
        "Erro. Verifique o resultado da transação na página de pedidos do site de compras.",
    },
    validator: {
      input: "Por favor, insira um {field} válido",
      intallmentCardOrCountryNotSupport:
        "O pagamento parcelado não é suportado por tipo de cartão ou país",
    },
    error: {
      timeout: "A rede está anormal, tente novamente mais tarde",
      cardNumber: "As informações do cartão estão incorretas",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
