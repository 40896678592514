import ElementUILocal from "element-ui/lib/locale/lang/nl";

import country from "./country/nl";
import state from "./state/nl";

export default {
  message: {
    title: "Kassa",
    powerByOnerway: 'Aangedreven door <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Voltooi de betaling binnen <span class="time">{time}</span> minuten, anders treedt er een time-out op de pagina op',
      billingAddressTip:
        "De afschrijving wordt op uw factuur weergegeven als {billingAddress}",
      cardInfo: "Kaartgegevens",
      cardNumber: "Kaartnummer",
      prepaidNumber: "Kaartnummer",
      summary: "Bestellingsoverzicht",
      expiredDate: "Geldigheidsperiode",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Voornaam",
      lastName: "Achternaam",
      holderName: "Naam pashouder",
      walletAccountName: "Naam",
      orderno: "Bestel ID",
      website: "Website URL",
      amount: "Bedrag",
      pay: "Betaal",
      payNow: "Nu betalen",
      cancel: "Annuleren",
      tip: "Tips",
      cancelPayTip: "Wilt u deze betaling annuleren?",
      yes: "Ja",
      no: "Nee",
      subscription: "Abonnement",
      schedule: "Betalingsperiode",
      endTime: "Eindtijd",
      subscriptionTip:
        "Na bevestiging van de betaling geeft u de handelaar toestemming om uw kaart in de toekomst volgens de voorwaarden te debiteren.",
      frequencyData: {
        D: "{time}dag/tijd",
      },
      endTimeData: "{year}jaar{month}maand{day}dag",
      billingAddress: "Factuuradres/Contactgegevens",
      country: "Land",
      province: "Staat/Provincie/Regio",
      address: "Adres",
      city: "Stad",
      postalCode: "Postcode",
      email: "E-mail",
      phone: "Telefoonnummer",
      street: "Straatnummer",
      number: "Huisnummer",
      identityNumber: "Belastingnummer",
      intallment: "Enscenering",
      intallmentPlan: "Afbetalingsplan",
      paymentMethod: "Betaalmethode",
      bankName: "Banknaam",
      iBan: "Bankrekeningnummer",
      contactName: "Naam contactpersoon",
      birthDate: "Geboortedatum",
      paymentCompleted: "Betaling voltooid",
      wechatPaymentTip: "Gebruik WeChat om de QR-code te scannen om te betalen",
      paymentPending: "Wachten op betaling",
      billingCountry: "Factureringsland",
      billingCountryTip:
        "Selecteer eerst het land van facturering, zodat we u een geschikte betaalmethode kunnen aanbevelen.",
      nextStep: "Volgende stap",
      changeCountryTip:
        "Nadat u van land bent gewisseld, zullen we u geschikte betaalmethoden aanbevelen.",
      scanCode: "Scan het",
      txnTime: "Transactie tijd",
      moreInfo: "Meer informatie",
      applePayNotSupport: "Dit apparaat ondersteunt geen Apple Pay.",
      policy: {
        prefix: "Ik heb het ",
        suffix: " gelezen en ga ermee akkoord",
      },
      privacy: "privacybeleid",
      intallmentTip:
        "Het kan zijn dat je rente moet betalen over de afbetalingen.",
      payInFull: "Volledig betalen",
      payIn: "Betalen in {count}",
      blikcode: "BLIK-code",
      unavailable: "Niet beschikbaar",
      notSupportPaymentTips:
        "De betalingsmethode is momenteel niet beschikbaar. Ga terug naar de selectiescherm.",
      returnText: "Terug naar selectie",
      returnMerchant: "Terug naar de verkoperspagina",
      cvvTips:
        "De CVC-code bevindt zich aan de achterkant van uw kaart aan de rechterkant van de witte handtekeningsstrook; het zijn altijd de laatste 3 cijfers voor Visa en Mastercard. Voor American Express zijn het 4 cijfers aan de voorkant van de kaart.",
      holderNameTips: "Volledige naam op de kaart",
      continue: "Doorgaan",
      validateCVVTitle: "Beveiligingsverificatie",
      validateCVVDesc:
        "Voor uw betalingsveiligheid, verifieer alstublieft de CVC/CVV van uw kaart",
    },
    dlocal: {
      amount: "bedrag",
      expirationDate: "termijnen",
      ticketNumber: "Ticketnummer",
      ticketImage: "Ticket afbeelding",
      downloadTicket: "Kaartje downloaden",
      searchForShops: "winkels zoeken",
      fullBoletoView: "Volledige Boleto View",
      back: "terugkeren",
      copySuccess: "Kopiëren",
      errorMsg: "Time-out. Kan parameters niet ophalen.",
      instructionsTitle: "Betalingsinstructies",
      instructionsContentPIX:
        "ID Betalingsinstructies:<br/>1. ID van de replicatietransactie<br/>2. Gebruik de ID om je PIX-betalingen te identificeren in je bankieren-app of lees de QR-code op de app van de bank.",
      instructionsContentRedpagos:
        "Je moet naar een Redpagos-winkel gaan om de betaling te doen.<br/>Door je id-kaartnummer in te spreken en te verduidelijken dat je een dlocal rekening wilt betalen, kun je de betaling doen.",
      instructionsContentOXXO:
        "1. Ga naar de dichtstbijzijnde oxxo winkel.<br/>2. Geef in het vakje aan dat je een oxxopay betaling wilt doen.<br/>3. 4. Dicteert het referentienummer op deze kaart aan de kassière om in te typen<br/>direct op het verkoopscherm.<br/>4. Voer de bijbehorende betaling uit met contant geld.<br/>5. Wanneer je de betaling bevestigt, krijg je van de kassier een afgedrukte bon. Hierop kun je controleren of het correct is gedaan. Bewaar dit betalingsbewijs.<br/>6. Ga voor meer informatie over je betaling naar ons helpcentrum",
      instructionsContentSPEI:
        "1. Onmiddellijke accreditatie.<br/>2. Zodra de bankoverschrijving is ontvangen, informeert dlocal de leverancier over het product/de dienst die u koopt.<br/>3. Als u problemen heeft met de betaling, bezoek dan onze klantenservice.",
      instructionsContentSencillito:
        'Betaalinstructie in persoon:<br/>Bij Sencillito kun je een betaling doen door een referentienummer op te geven en duidelijk te maken dat je de factuur van DLOCAL wilt betalen.<br/>Online betaalinstructie:<br/>Om de betaling te doen moet je Portal Sencillito binnengaan, kies "dlocal" in de bedrijfszoekmachine en voer de code in die op de bon 134216 staat.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Bankgegevens",
      bankDetailsSPEI:
        'Log in op je online bankieren en maak over naar de volgende rekening. Contante betalingen zijn niet mogelijk.<br/>Bestemmingsbank:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Begunstigde: <span class="detail-value">{beneficiary}</span><br/>Bedrag: <span class="detail-value">{amount} {currency}</span><br/>Betalingsconcept: <span class="detail-value">{concept}</span><br/>Referentie: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Type betaling",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Bedrag",
      orderTips: "Gelieve over te maken naar het volgende account",
      numberTitle: "Rekeningnummer (alleen voor deze transactie)",
      copyBtnText: "Rekeningnummer kopiëren",
      expireText: "Bestelling vervalt in {time}",
      bankName: "Banknaam",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "Betaling succesvol",
      failed: "Betaling mislukt",
      unknow: "Betalend",
      unknowTip:
        "Ververs de bestelstatus om het betalingsresultaat te controleren",
      jump: 'Terugkeren naar de handelaar in <span class="hightlight">{time}s</span>',
      reason: "Reden",
      processing: "Bezig met verwerken....",
      timeout: "De pagina loopt uit, start de betaling opnieuw",
      invalid: "Ongeldig transactieverzoek",
      cancelled:
        "De betalingsaanvraag is geannuleerd, gelieve de betaling niet te herhalen",
      emptyPaymentMethod:
        "Er zijn geen betaalmethoden geconfigureerd voor dit land.",
      error:
        "Fout. Controleer het transactieresultaat op de bestelpagina van de winkelwebsite.",
    },
    validator: {
      input: "Voer een geldig {field} in",
      intallmentCardOrCountryNotSupport:
        "Termijnbetaling wordt niet ondersteund per kaarttype of land",
    },
    error: {
      timeout: "Het netwerk is abnormaal, probeer het later opnieuw",
      cardNumber: "Kaartgegevens zijn onjuist",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
