import zhLocale from "element-ui/lib/locale/lang/ja";

import country from "./country/ja";
import state from "./state/ja";

export default {
  message: {
    title: "レジカウンター",
    powerByOnerway: '<span class="ic-powerby"></span> による技術サポート',
    checkout: {
      timeTip:
        '<span class="time">{time}</span> 分以内に支払いを完了すると、ページがタイムアウトします',
      billingAddressTip: "請求元は{billingAddress}として反映されます",
      cardInfo: "カード情報",
      cardNumber: "カード番号",
      prepaidNumber: "カード番号",
      summary: "注文情報",
      expiredDate: "有効期限",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "First name",
      lastName: "Family name",
      holderName: "クレジットカード名義人氏名",
      walletAccountName: "なまえ",
      orderno: "注文ID",
      website: "URL",
      amount: "ご請求額",
      pay: "支払う",
      payNow: "すぐに支払う",
      cancel: "キャンセル",
      tip: "ヒント",
      cancelPayTip: "この支払いを放棄しますか？",
      yes: "はい",
      no: "いいえ",
      subscription: "サブスクリプション",
      schedule: "返済期間",
      endTime: "締め切り",
      subscriptionTip:
        "支払いを確認することにより、マーチャントが条件に従って将来あなたのカードから引き落とすことを許可します。",
      frequencyData: {
        D: "{time}日/時間",
      },
      endTimeData: "{year}年{month}月{day}号",
      billingAddress: "請求先情報",
      country: "国選択",
      province: "州/県/地域",
      address: "住所",
      city: "市",
      postalCode: "郵便番号",
      email: "メールアドレス",
      phone: "電話番号",
      street: "街路番号",
      number: "家屋番号",
      identityNumber: "税番号",
      intallment: "分割",
      intallmentPlan: "分割予定",
      paymentMethod: "お支払い方法",
      bankName: "銀行名",
      iBan: "銀行口座",
      contactName: "連絡窓口",
      birthDate: "生年月日",
      paymentCompleted: "支払いが完了しました",
      wechatPaymentTip: "WeChatを使用してQRコードをスキャンして支払います",
      paymentPending: "入金待ち",
      billingCountry: "請求国",
      billingCountryTip:
        "適切な支払い方法をお勧めできるよう、最初に請求国を選択してください。",
      nextStep: "次のステップ",
      changeCountryTip: "国を切り替えた後、適切な支払い方法をお勧めします。",
      scanCode: "スキャンコード支払い",
      txnTime: "取引時間",
      moreInfo: "詳しくは",
      applePayNotSupport: "このデバイスは Apple Pay をサポートしていません。",
      policy: {
        prefix: "",
        suffix: "を読んで同意します",
      },
      privacy: "プライバシーポリシー",
      intallmentTip: "分割払いの利息を支払わなければならない場合もある。",
      payInFull: "全額を支払う",
      payIn: "{count}で支払う",
      blikcode: "BLIKコード",
      unavailable: "利用できません",
      notSupportPaymentTips:
        "現在、この支払い方法は利用できません。選択ページに戻ってください。",
      returnText: "選択に戻る",
      returnMerchant: "店舗ページに戻ります",
      cvvTips:
        "CVCコードはカードの裏面の白い署名欄の右側にあります；VisaとMastercardの場合、常に最後の3桁です。American Expressの場合は、カードの表面に4桁あります。",
      holderNameTips: "カード上のフルネーム",
      continue: "続ける",
      validateCVVTitle: "セキュリティ確認",
      validateCVVDesc:
        "支払いの安全のために、カードのCVC/CVVを確認してください",
    },
    dlocal: {
      amount: "金額です",
      expirationDate: "締切期日",
      ticketNumber: "チケット番号",
      ticketImage: "チケット画像",
      downloadTicket: "ダウンロードチケット",
      searchForShops: "ペイメントショップ検索",
      fullBoletoView: "Boleto の全景",
      back: "戻る",
      copySuccess: "コピー",
      errorMsg: "タイムアウト。パラメータの取得に失敗しました。",
      instructionsTitle: "支払指示",
      instructionsContentPIX:
        "ID支払い方法:<br/>1. レプリケーション・トランザクションID<br/>2. 銀行アプリでPIX決済を識別するためにIDを使用するか、銀行のアプリでQRコードを読み取ります。",
      instructionsContentRedpagos:
        "支払いにはレッドパゴス店舗に行く必要があります。<br/>idカード番号を言い、dlocalの料金を支払いたいことを明確にすることで、支払いが可能になります",
      instructionsContentOXXO:
        "1. 最寄りのオクソストアへ行く。<br/>2. oxxopayで支払いたい旨を箱に記入する。<br/>3. このカードに記載されている参照番号をレジで入力する。<br/>販売画面に直接入力する。<br/>4. 対応する支払いを現金で行う。<br/>5. 支払いを確認すると、レジ係が印刷された伝票を渡す。ここで支払いが正しく行われたことを確認できます。この支払い証明書を保管してください。<br/>6. お支払いに関する詳細は、ヘルプセンターをご覧ください。",
      instructionsContentSPEI:
        "1. 即時認定<br/>2. 銀行振込が確認され次第、dlocalからご購入の商品・サービスをお届けします。<br/>3. お支払いに問題がある場合は、カスタマーサービスポータルをご覧ください。",
      instructionsContentSencillito:
        "ご来店によるお支払い<br/>センシリートの営業所にて、参照番号をお伝えいただき、DLOCALの請求書への支払いを希望することを明確にすることで、支払いを行うことができます。<br/>オンラインでのお支払い<br/>お支払いの際は、Portal Sencillitoに入り、検索エンジンで「dlocal」を選択し、伝票134216に記載されているコードを入力してください。",
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "振込先銀行",
      bankDetailsSPEI:
        'オンラインバンキングにログインし、次の口座にお振込みください。現金でのお支払いはできませんのでご注意ください。<br/>名義人: <span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>受取人: <span class="detail-value">{beneficiary}</span><br/>金額: <span class="detail-value">{amount} {currency}</span><br/>支払コンセプト: <span class="detail-value">{concept}</span><br/>参照: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "お支払いの種類",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "金額",
      orderTips: "次の口座に振り込んでください",
      numberTitle: "口座番号（この取引のみに使用）",
      copyBtnText: "口座番号をコピー",
      expireText: "注文の有効期限 {time}",
      bankName: "銀行名",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "支払い完了",
      failed: "支払いに失敗しました",
      unknow: "支払い",
      unknowTip: "注文状況を更新して支払い結果を確認してください",
      jump: '<span class="hightlight">{time}秒</span>後に商人に戻ります',
      reason: "理由",
      processing: "処理....",
      timeout: "ページがタイムアウトしました。支払いを再開してください",
      invalid: "無効なトランザクションリクエスト",
      cancelled:
        "支払い申請はキャンセルされました。再度支払いをしないでください",
      emptyPaymentMethod: "この国に設定されている支払い方法はありません。",
      error:
        "エラー。 ショッピングサイトの注文ページで取引結果をご確認ください。",
    },
    validator: {
      input: "有効な{field}を入力してください",
      intallmentCardOrCountryNotSupport:
        "分割払いはカードの種類や国によってはサポートされていません",
    },
    error: {
      timeout: "ネットワークエラー、後でもう一度やり直してください",
      cardNumber: "カード情報が間違っている",
    },
    country,
    state,
  },
  ...zhLocale,
};
