import ElementUILocal from "element-ui/lib/locale/lang/pl";

import country from "./country/pl";
import state from "./state/pl";

export default {
  message: {
    title: "Kasa",
    powerByOnerway: 'Zasilane przez <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Proszę dokończyć płatność w ciągu <span class="time">{time}</span> minut, w przeciwnym razie strona wygaśnie',
      billingAddressTip:
        "Opłata pojawi się na Twoim rachunku jako {billingAddress}",
      cardInfo: "Informacje o karcie",
      cardNumber: "Numer karty",
      prepaidNumber: "Numer karty",
      summary: "Podsumowanie zamówienia",
      expiredDate: "Okres ważności",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Imie",
      lastName: "Nazwisko",
      holderName: "Imię właściciela karty",
      walletAccountName: "Nazwa",
      orderno: "ID zamówienia",
      website: "Stronie internetowej",
      amount: "Kwota",
      pay: "Płać",
      payNow: "Zapłać teraz",
      cancel: "Anuluj",
      tip: "Wskazówka",
      cancelPayTip: "Czy chcesz anulować tę płatność?",
      yes: "Tak",
      no: "Nie",
      subscription: "Subskrypcja",
      schedule: "Okres płatności",
      endTime: "Termin",
      subscriptionTip:
        "Po potwierdzeniu płatności zezwalasz sprzedawcy na obciążanie Twojej karty zgodnie z warunkami w przyszłości.",
      frequencyData: {
        D: "{time}dzień/godzina",
      },
      endTimeData: "{year}rok{month}miesiąc{day}dzień",
      billingAddress: "Adres rozliczeniowy/Dane kontaktowe",
      country: "Kraj",
      province: "Stan/województwo/region",
      address: "Adres",
      city: "Miasto",
      postalCode: "Kod Pocztowy",
      email: "E-mail",
      phone: "Numer telefonu",
      street: "numer ulicy",
      number: "Numer domu",
      identityNumber: "Numer podatkowy",
      intallment: "Rata",
      intallmentPlan: "Plan ratalny",
      paymentMethod: "Metoda płatności",
      bankName: "Nazwa banku",
      iBan: "Numer Konta Bankowego",
      contactName: "Nazwa kontaktu",
      birthDate: "Data urodzenia",
      paymentCompleted: "Płatność zakończona",
      wechatPaymentTip: "Użyj WeChat do zeskanowania kodu QR, aby zapłacić",
      paymentPending: "Czekać na płatność",
      billingCountry: "Kraj rozliczenia",
      billingCountryTip:
        "Wybierz najpierw kraj rozliczenia, abyśmy mogli polecić Ci odpowiednią metodę płatności.",
      nextStep: "Następny krok",
      changeCountryTip:
        "Po zmianie kraju polecimy Ci odpowiednie metody płatności.",
      scanCode: "Zeskanuj to",
      txnTime: "Czas transakcji",
      moreInfo: "Więcej informacji",
      applePayNotSupport: "To urządzenie nie obsługuje Apple Pay.",
      policy: {
        prefix: "Przeczytałem i zgadzam się z ",
        suffix: "",
      },
      privacy: "Polityką prywatności",
      intallmentTip: "Być może będziesz musiał zapłacić odsetki od rat.",
      payInFull: "Zapłacić w całości",
      payIn: "Płatność w {count}",
      blikcode: "Kod BLIK",
      unavailable: "Niedostępny",
      notSupportPaymentTips:
        "Metoda płatności jest obecnie niedostępna. Proszę wrócić do strony wyboru.",
      returnText: "Powrót do wyboru",
      returnMerchant: "Powrót do strony sprzedawcy",
      cvvTips:
        "Kod CVC znajduje się na odwrocie karty po prawej stronie białego paska do podpisu; zawsze są to ostatnie 3 cyfry dla Visa i Mastercard. Dla American Express są to 4 cyfry na przedniej stronie karty.",
      holderNameTips: "Pełne imię i nazwisko na karcie",
      continue: "Kontynuuj",
      validateCVVTitle: "Weryfikacja bezpieczeństwa",
      validateCVVDesc:
        "Dla bezpieczeństwa płatności, zweryfikuj kod CVC/CVV swojej karty",
    },
    dlocal: {
      amount: "finanse",
      expirationDate: "Termin",
      ticketNumber: "Numer biletu",
      ticketImage: "Obraz biletu",
      downloadTicket: "Bilet do pobrania",
      searchForShops: "wyszukiwanie sklepów",
      fullBoletoView: "Pełny widok Boleto",
      back: "powrót",
      copySuccess: "Kopiuj",
      errorMsg: "Przekroczono limit czasu. Nie można uzyskać parametrów.",
      instructionsTitle: "Instrukcja płatności",
      instructionsContentPIX:
        "Instrukcje dotyczące płatności ID:<br/>1. Identyfikator transakcji replikacji<br/>2. Użyj identyfikatora do identyfikacji płatności PIX w aplikacji bankowej lub odczytaj kod QR w aplikacji banku.",
      instructionsContentRedpagos:
        "Aby dokonać płatności, należy udać się do sklepu redpagos.<br/>Podając numer karty identyfikacyjnej i wyjaśniając, że chcesz zapłacić rachunek lokalny, będziesz mógł dokonać płatności.",
      instructionsContentOXXO:
        "1. Udaj się do najbliższego sklepu oxxo.<br/>2. Wskaż w polu, że chcesz dokonać płatności oxxopay.<br/>3. Przekaż kasjerowi numer referencyjny na tej karcie w celu wpisania go<br/>bezpośrednio na ekranie sprzedaży.<br/>4. Dokonaj odpowiedniej płatności gotówką.<br/>5. Po potwierdzeniu płatności kasjer wyda wydrukowany kupon. W tym miejscu można sprawdzić, czy płatność została wykonana prawidłowo. Zachowaj ten dowód płatności.<br/>6. Aby uzyskać więcej informacji na temat płatności, odwiedź nasze centrum pomocy",
      instructionsContentSPEI:
        "1. Natychmiastowa akredytacja.<br/>2. Po otrzymaniu przelewu bankowego dlocal poinformuje o dostarczeniu zakupionego produktu/usługi.3. Jeśli masz jakiekolwiek problemy z płatnością, odwiedź nasz portal obsługi klienta.",
      instructionsContentSencillito:
        'Instrukcja płatności osobiście:<br/>W miejscu prowadzenia działalności przez Sencillito można dokonać płatności, podając numer referencyjny i wyraźnie zaznaczając, że chce się opłacić fakturę DLOCAL.<br/>Instrukcja płatności online:<br/>Aby dokonać płatności należy wejść na Portal Sencillito, wybrać "dlocal" w wyszukiwarce firm i wpisać kod wskazany na voucherze 134216..',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Dane bankowe",
      bankDetailsSPEI:
        'Zaloguj się do swojej bankowości internetowej i dokonaj przelewu na kolejne konto. Płatności gotówkowe nie są możliwe.<br/>Bank docelowy:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Beneficjent: <span class="detail-value">{beneficiary}</span><br/Kwota: <span class="detail-value">{amount} {currency}</span><br/>Koncepcja płatności: <span class="detail-value">{concept}</span><br/>Odniesienie: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Rodzaj płatności",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Kwota",
      orderTips: "Proszę przelać na poniższe konto",
      numberTitle: "Numer konta (tylko dla tej transakcji)",
      copyBtnText: "Skopiuj numer konta",
      expireText: "Zamówienie wygasa za {time}",
      bankName: "Nazwa banku",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "Płatność powiodła się",
      failed: "Płatność nie powiodła się",
      unknow: "Płatność",
      unknowTip:
        "Proszę odświeżyć status zamówienia, aby sprawdzić wynik płatności",
      jump: 'Powrót do sprzedawcy za <span class="hightlight">{time}s</span>',
      reason: "Powód",
      processing: "Przetwarzanie…",
      timeout: "Strona wygasła, proszę ponownie zainicjować płatność",
      invalid: "Nieprawidłowe żądanie transakcji",
      cancelled:
        "Wniosek o płatność został anulowany, proszę nie powtarzać płatności",
      emptyPaymentMethod:
        "Nie ma skonfigurowanych form płatności dla tego kraju.",
      error:
        "Błąd. Sprawdź wynik transakcji na stronie zamówienia w sklepie internetowym.",
    },
    validator: {
      input: "Proszę podać prawidłowe {field}",
      intallmentCardOrCountryNotSupport:
        "Płatność ratalna nie jest obsługiwana w zależności od typu karty lub kraju",
    },
    error: {
      timeout: "Sieć działa nieprawidłowo, spróbuj ponownie później",
      cardNumber: "Dane karty są nieprawidłowe",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
