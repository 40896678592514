import ElementUILocal from "element-ui/lib/locale/lang/zh-CN";

import country from "./country/zh";
import state from "./state/zh";

export default {
  message: {
    title: "收银台",
    powerByOnerway: '由<span class="ic-powerby"></span>提供技术支持',
    checkout: {
      timeTip:
        '请在 <span class="time">{time}</span> 分钟内完成付款，否则页面将超时',
      billingAddressTip: "所扣费用会在您的帐单上显示为 {billingAddress}",
      cardInfo: "卡信息",
      cardNumber: "卡号",
      prepaidNumber: "卡号",
      summary: "订单摘要",
      expiredDate: "有效期",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "名",
      lastName: "姓",
      holderName: "持卡人姓名",
      walletAccountName: "姓名",
      orderno: "订单号",
      website: "网址",
      amount: "金额",
      pay: "支付",
      payNow: "立即支付",
      cancel: "取消",
      tip: "提示",
      cancelPayTip: "你想放弃这次付款?",
      yes: "是",
      no: "否",
      subscription: "订阅",
      schedule: "支付周期",
      endTime: "截止日期",
      subscriptionTip: "确认支付后，表示您允许商户在未来按条款从您的卡中扣款。",
      frequencyData: {
        D: "{time}天/次",
      },
      endTimeData: "{year}年{month}月{day}日",
      billingAddress: "账单地址/联系信息",
      country: "国家",
      province: "州/省/地区",
      address: "地址",
      city: "城市",
      postalCode: "邮政编码",
      email: "邮箱",
      phone: "电话号码",
      street: "街道号",
      number: "门牌号",
      identityNumber: "税务号",
      intallment: "分期",
      intallmentPlan: "分期计划",
      paymentMethod: "支付方式",
      bankName: "银行名称",
      iBan: "银行账号",
      contactName: "联系人姓名",
      birthDate: "出生日期",
      paymentCompleted: "已完成支付",
      wechatPaymentTip: "请使用微信扫描二维码支付",
      paymentPending: "等待支付",
      billingCountry: "账单国家",
      billingCountryTip: "请先选择账单国家，以便于我们给您推荐合适的支付方式。",
      nextStep: "下一步",
      changeCountryTip: "切换国家后，我们会给您推荐合适的支付方式。",
      scanCode: "扫码支付",
      txnTime: "交易时间",
      moreInfo: "更多信息",
      applePayNotSupport: "本设备不支持Apple Pay。",
      policy: {
        prefix: "我已阅读并同意",
        suffix: "",
      },
      privacy: "隐私政策",
      intallmentTip: "分期金额可能涉及额外利息",
      payInFull: "全额付款",
      payIn: "分期{count}期",
      blikcode: "BLIK码",
      unavailable: "不可用",
      notSupportPaymentTips: "该支付方式目前不可用。请返回选择页面。",
      returnText: "返回选择",
      returnMerchant: "返回商户页面",
      cvvTips:
        "CVC代码位于卡片背面的白色签名条的右侧；对于Visa和Mastercard，总是最后的3位数字。对于American Express，代码位于卡片正面的4位数字。",
      holderNameTips: "卡片上的全名",
      continue: "继续",
      validateCVVTitle: "安全验证",
      validateCVVDesc: "为了您的支付安全，请验证您银行卡的CVC/CVV",
    },
    dlocal: {
      amount: "金额",
      expirationDate: "截至日期",
      ticketNumber: "票号",
      ticketImage: "票码",
      downloadTicket: "下载票码",
      searchForShops: "搜寻支付商铺",
      fullBoletoView: "完整的Boleto视图",
      back: "返回",
      copySuccess: "复制",
      errorMsg: "超时。获取参数失败。",
      instructionsTitle: "支付说明",
      instructionsContentPIX:
        "ID付款说明:<br/>1. 复制交易 ID<br/>2. 使用 ID 在银行应用程序中识别 PIX 支付，或在银行应用程序中读取二维码。",
      instructionsContentRedpagos:
        "您需要前往 Redpagos 商店進行支付。<br/>說出您的 ID 卡號並說明您要支付的是 dlocal 賬單，您就可以付款了。",
      instructionsContentOXXO:
        "1. 前往最近的 OXXO 商店。<br/>2. 在方框内注明您要进行 oxxopay 支付。<br/>3. 将此卡上的参考编号口述给收银员，以便在销售屏幕上直接输入。<br/>直接在销售屏幕上输入。<br/>4. 用现金支付相应款项。<br/>5. 确认付款后，收银员会给您一张打印凭证。您可以在此确认付款是否正确。请保存好这张付款凭证。<br/>6. 有关付款的更多信息，请访问我们的帮助中心",
      instructionsContentSPEI:
        "1. 即时认证。<br/>2. 收到银行汇款后，dlocal 将通知交付您购买的产品/服务。3. 如果您在付款时遇到任何问题，请访问我们的客户服务门户。",
      instructionsContentSencillito:
        "当面付款指南:<br/>在Sencillito的营业地点，您可以通过提供参考号码并明确表示您要支付DLOCAL发票来付款。<br/>网上付款指南:<br/>您必须进入sencilto门户网站进行付款，在公司搜索引擎中选择“dlocal”，然后输入凭证134216上显示的代码。",
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "银行信息",
      bankDetailsSPEI:
        '登录您的网上银行并转帐到下一个账户。请注意，不支持现金支付。<br/>目的银行: <span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>受益人: <span class="detail-value">{beneficiary}</span><br/>金额: <span class="detail-value">{amount} {currency}</span><br/>付款概念: <span class="detail-value">{concept}</span><br/>凭证: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "付款类型",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "金额",
      orderTips: "请转账到以下账户",
      numberTitle: "账号（仅用于此交易）",
      copyBtnText: "复制账号",
      expireText: "订单有效期 {time}",
      bankName: "银行名称",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "支付成功",
      failed: "支付失败",
      unknow: "支付中",
      unknowTip: "请刷新订单状态查看付款结果",
      jump: '<span class="hightlight">{time}s</span>后返回商户',
      reason: "原因",
      processing: "处理中....",
      timeout: "页面超时，请重新发起支付",
      invalid: "无效的交易请求",
      cancelled: "支付申请已取消，请勿重复支付",
      emptyPaymentMethod: "该国家未配置支付方式.",
      error: "系统异常，请到购物网站订单页面查询交易结果。",
    },
    validator: {
      input: "请输入有效的{field}",
      intallmentCardOrCountryNotSupport: "卡类型或国家不支持分期支付!",
    },
    error: {
      timeout: "网络异常，请稍后重试",
      cardNumber: "卡信息不正确",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
