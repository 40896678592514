import zhLocale from "element-ui/lib/locale/lang/zh-TW";

import country from "./country/zh-tw";
import state from "./state/zh-tw";

export default {
  message: {
    title: "收銀台",
    powerByOnerway: '由<span class="ic-powerby"></span>提供技術支持',
    checkout: {
      timeTip:
        '請在 <span class="time">{time}</span> 分鐘內完成付款，否則頁面將超時',
      billingAddressTip: "所扣費用會在您的帳單上顯示為 {billingAddress}",
      cardInfo: "卡信息",
      cardNumber: "卡號",
      prepaidNumber: "卡號",
      summary: "訂單摘要",
      expiredDate: "有效期",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "名",
      lastName: "姓",
      holderName: "持卡人姓名",
      walletAccountName: "姓名",
      orderno: "訂單編號",
      website: "網址",
      amount: "金額",
      pay: "支付",
      payNow: "立即支付",
      cancel: "取消",
      tip: "提示",
      cancelPayTip: "你想放棄這次付款?",
      yes: "是",
      no: "否",
      subscription: "訂閱",
      schedule: "支付週期",
      endTime: "截止日期",
      subscriptionTip:
        "確認支付後，表示您允許商戶在未來按條款從您的卡中扣款。 ",
      frequencyData: {
        D: "{time}天/次",
      },
      endTimeData: "{year}年{month}月{day}日",
      billingAddress: "賬單地址/聯繫信息",
      country: "國家",
      province: "州/省/地區",
      address: "地址",
      city: "城市",
      postalCode: "郵政編碼",
      email: "郵箱",
      phone: "電話號碼",
      street: "街道號",
      number: "門牌號",
      identityNumber: "稅務號",
      intallment: "分期",
      intallmentPlan: "分期計劃",
      paymentMethod: "支付方式",
      bankName: "銀行名稱",
      iBan: "銀行賬號",
      contactName: "聯繫人姓名",
      birthDate: "出生日期",
      paymentCompleted: "已完成支付",
      wechatPaymentTip: "請使用微信掃描二維碼支付",
      paymentPending: "等待支付",
      billingCountry: "賬單國家",
      billingCountryTip: "請先選擇賬單國家，以便於我們給您推薦合適的支付方式。",
      nextStep: "下一步",
      changeCountryTip: "切換國家後，我們會給您推薦合適的支付方式。",
      scanCode: "掃碼支付",
      txnTime: "交易時間",
      moreInfo: "更多信息",
      applePayNotSupport: "此設備不支持 Apple Pay。",
      policy: {
        prefix: "我已閱讀並同意",
        suffix: "",
      },
      privacy: "隱私權政策",
      intallmentTip: "分期金額可能涉及額外利息",
      payInFull: "全額付款",
      payIn: "分期{count}期",
      blikcode: "BLIK碼",
      unavailable: "不可用",
      notSupportPaymentTips: "該支付方式目前不可用。請返回選擇頁面。",
      returnText: "返回選擇",
      returnMerchant: "返回商戶頁面",
      cvvTips:
        "CVC代碼位於卡片背面的白色簽名條的右側；對於Visa和Mastercard，總是最後的3位數字。對於American Express，代碼位於卡片正面的4位數字。",
      holderNameTips: "卡片上的全名",
      continue: "繼續",
      validateCVVTitle: "安全驗證",
      validateCVVDesc: "為了您的支付安全，請驗證您銀行卡的CVC/CVV",
    },
    dlocal: {
      amount: "金額",
      expirationDate: "截止日期",
      ticketNumber: "票號",
      ticketImage: "票碼",
      downloadTicket: "下載票碼",
      searchForShops: "搜尋支付商鋪",
      fullBoletoView: "完整的Boleto視圖",
      back: "返回",
      copySuccess: "複製",
      errorMsg: "超時。獲取參數失敗。",
      instructionsTitle: "支付說明",
      instructionsContentPIX:
        "ID付款說明:<br/>1. 複製交易 ID<br/>2. 使用 ID 在銀行應用程序中識別 PIX 支付，或在銀行應用程序中讀取二維碼。",
      instructionsContentRedpagos:
        "您需要前往 Redpagos 商店進行支付。<br/>說出您的 ID 卡號並說明您要支付的是 dlocal 賬單，您就可以付款了。",
      instructionsContentOXXO:
        "1. 前往最近的 OXXO 商店。<br/>2. 在方框內註明您要進行 oxxopay 支付。<br/>3. 將此卡上的參考編號口述給收銀員，以便在銷售屏幕上直接輸入。<br/>直接在銷售屏幕上輸入。<br/>4. 用現金支付相應款項。<br/>5. 確認付款後，收銀員會給您一張打印憑證。您可以在此確認付款是否正確。請保存好這張付款憑證。<br/>6. 有關付款的更多信息，請訪問我們的幫助中心",
      instructionsContentSPEI:
        "1. 即時認證。<br/>2. 收到銀行匯款後，dlocal 將通知交付您購買的產品/服務。<br/>3. 如果您在付款時遇到任何問題，請訪問我們的客戶服務門戶。",
      instructionsContentSencillito:
        "當面付款指南:<br/>在Sencillito的營業地點，您可以通過提供參考號碼並明確表示您要支付DLOCAL發票來付款。<br/>網上付款指南:<br/>您必須進入sencilto門戶網站進行付款，在公司搜索引擎中選擇“dlocal”，然後輸入憑證134216上顯示的代碼。",
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "銀行信息",
      bankDetailsSPEI:
        '登錄您的網上銀行並轉帳到下一個賬戶。請注意，不支持現金支付。<br/>目的銀行: <span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>受益人: <span class="detail-value">{beneficiary}</span><br/>金額: <span class="detail-value">{amount} {currency}</span><br/>付款概念: <span class="detail-value">{concept}</span><br/>憑證: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "付款類型",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "金額",
      orderTips: "請轉賬到以下帳戶",
      numberTitle: "帳號（僅用於此交易）",
      copyBtnText: "複製帳號",
      expireText: "訂單到期時間 {time}",
      bankName: "銀行名稱",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "支付成功",
      failed: "支付失敗",
      unknow: "支付中",
      unknowTip: "請刷新訂單狀態查看付款結果",
      jump: '<span class="hightlight">{time}s</span>後返回商戶',
      reason: "原因",
      processing: "處理中....",
      timeout: "頁面超時，請重新發起支付",
      invalid: "無效的交易請求",
      cancelled: "支付申請已取消，請勿重複支付",
      emptyPaymentMethod: "該國家未配置支付方式.",
      error: "錯誤。 請在購物網站訂單頁面查看交易結果。",
    },
    validator: {
      input: "請輸入有效的{field}",
      intallmentCardOrCountryNotSupport: "卡類型或國家不支持分期支付",
    },
    error: {
      timeout: "網絡異常，請稍後重試",
      cardNumber: "卡信息不正確",
    },
    country,
    state,
  },
  ...zhLocale,
};
