import ElementUILocal from "element-ui/lib/locale/lang/es";

import country from "./country/es";
import state from "./state/es";

export default {
  message: {
    title: "Caja",
    powerByOnerway: 'Potenciado por <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Complete el pago dentro de los <span class="time">{time}</span> minutos, de lo contrario, la página se agotará.',
      billingAddressTip:
        "El cargo aparecerá en su factura como {billingAddress}.",
      cardInfo: "Información de la tarjeta",
      cardNumber: "Número de tarjeta",
      prepaidNumber: "Número de tarjeta",
      summary: "Resumen del pedido",
      expiredDate: "Fecha de caducidad",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Nombre de pila",
      lastName: "Apellido",
      holderName: "Nombre del titular de la tarjeta",
      walletAccountName: "Nombre",
      orderno: "ID de pedido",
      website: "Sitio web",
      amount: "Monto",
      pay: "Pago",
      payNow: "Pagar ahora",
      cancel: "Cancelar",
      tip: "Consejos",
      cancelPayTip: "¿Quiere renunciar a este pago?",
      yes: "sí",
      no: "No",
      subscription: "Suscripción",
      schedule: "Calendario de pago",
      endTime: "Fecha final",
      subscriptionTip:
        "Al confirmar su pago, permite que el comerciante cargue su tarjeta para pagos futuros de acuerdo con sus términos y condiciones.",
      frequencyData: {
        D: "Una vez cada {time} días",
      },
      endTimeData: "{month} {day}, {year}",
      billingAddress: "Dirección de facturación/Información de contacto",
      country: "País",
      province: "Estado/Provincia/Región",
      address: "Habla a",
      city: "Pueblo/Ciudad",
      postalCode: "Código Postal",
      email: "Correo electrónico",
      phone: "Número de teléfono",
      street: "Número de calle",
      number: "Número",
      identityNumber: "Identificación del impuesto",
      intallment: "Pago a plazos",
      intallmentPlan: "Plan de instalación",
      paymentMethod: "Método de pago",
      bankName: "Nombre del banco",
      iban: "IBAN",
      contactName: "Nombre de contacto",
      birthDate: "Fecha de nacimiento",
      paymentCompleted: "Pago completado",
      wechatPaymentTip: "Utilice WeChat para escanear el código QR para pagar",
      paymentPending: "Esperar el pago",
      billingCountry: "País de facturación",
      billingCountryTip:
        "Seleccione primero el país de facturación para que podamos recomendarle un método de pago adecuado.",
      nextStep: "Próximo paso",
      changeCountryTip:
        "Después de cambiar de país, le recomendaremos métodos de pago adecuados.",
      scanCode: "Escanealo",
      txnTime: "Tiempo de transacción",
      moreInfo: "Más información",
      applePayNotSupport: "Este dispositivo no es compatible con Apple Pay.",
      policy: {
        prefix: "He leído y acepto la ",
        suffix: "",
      },
      privacy: "Política de Privacidad",
      intallmentTip: "Es posible que tenga que pagar intereses por los plazos.",
      payInFull: "Pague íntegramente",
      payIn: "Paga en {count}",
      blikcode: "Código BLIK",
      unavailable: "Español",
      notSupportPaymentTips:
        "El método de pago no está disponible en este momento. Por favor, vuelva a la página de selección.",
      returnText: "Volver a la selección",
      returnMerchant: "Volver a la página de comerciantes",
      cvvTips:
        "El código CVC se encuentra en la parte posterior de su tarjeta, en el lado derecho de la franja blanca de la firma; siempre son los últimos 3 dígitos para Visa y Mastercard. Para American Express, son 4 dígitos en la parte frontal de la tarjeta.",
      holderNameTips: "Nombre completo en la tarjeta",
      continue: "Continuar",
      validateCVVTitle: "Verificación de seguridad",
      validateCVVDesc:
        "Para la seguridad de su pago, por favor verifique el CVC/CVV de su tarjeta",
    },
    dlocal: {
      amount: "Importe",
      expirationDate: "Fecha de expiración",
      ticketNumber: "Número de billete",
      ticketImage: "Imagen del billete",
      downloadTicket: "Descargar billete",
      searchForShops: "Buscar tiendas",
      fullBoletoView: "Vista Boleto Completo",
      back: "VOLVER",
      copySuccess: "Copiar",
      errorMsg:
        "Tiempo de espera agotado. No se pueden obtener los parámetros.",
      instructionsTitle: "Instrucción de pago",
      instructionsContentPIX:
        "1. ID de la transacción de replicación<br/>2. Use o ID para identificar seus pagamentos PIX no aplicativo do seu banco.",
      instructionsContentRedpagos:
        "Deberás ir hasta un local de REDPAGOS para realizar el pago.<br/>Diciendo tu número de cédula y aclarando que quieres pagar una factura de DLOCAL podrás realizar el pago",
      instructionsContentOXXO:
        "1. Acude a la tienda OXXO más cercana.<br/>2. Indica en caja que quieres realizar un pago de OXXOPay.<br/>3. Dicta al cajero el número de referencia en esta ficha para que la teclee<br/>4. Directamente en la pantalla de venta<br/>5. Realiza el pago correspondiente con dinero en efectivo.<br/>6. Al confirmar tu pago, el cajero te entregará un comprobante impreso. En él podrás verificar que se haya realizado correctamente. Conserva este.<br/>7. Comprobante de pago.",
      instructionsContentSPEI:
        "1. Acreditación inmediata.<br/>2. Una vez recibida la transferencia bancaria, dLocal informará al comercio, el cual procederá a hacer entrega del producto/servicio que estás adquiriendo.",
      instructionsContentSencillito:
        'Pago Presencial:<br/>En un local de Sencillito diciendo el número de referencia y aclarando que quieres pagar una factura de DLOCAL podrás realizar el pago.<br/>Pago online:<br/>Para realizar el pago deberás entrar al <a href="https://www.sencillito.com/pagos-de-la-factura?industriaId=14&convenioId=2101" target="_blank">Portal Sencillito</a>, elije "DLOCAL" en el buscador de empresas y digitar el Código indicado en el voucher {number}.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Desde cualquier banco",
      bankDetailsSPEI:
        '<p class="bank-desc">Ingresa a tu banca en línea y realiza la transferencia a la siguiente cuenta. Recuerda que no se pueden hacer pagos en ventanilla.</p><div class="bank-info-box"><p class="bank-info-item"><span class="detail-title">Banco destino:</span><span class="detail-value">{bank}</span></p><p class="bank-info-item"><span class="detail-title">{clabe}: </span><span class="detail-value">{bankAccount2}</span></p><p class="bank-info-item"><span class="detail-title">Beneficiario: </span><span class="detail-value">{beneficiary}</span></p><p class="bank-info-item"><span class="detail-title">Importe: </span><span class="detail-value">{amount} {currency}</span></p><p class="bank-info-item"><span class="detail-title">Concepto de pago: </span><span class="detail-value">{concept}</span></p><p class="bank-info-item"><span class="detail-title">Referencia: </span><span class="detail-value">{reference}</span></p></div>',
      paymentTypeTitle: "Instrucción de pago",
      paymentTypeServipag:
        'Sigue estos pasos para pagar on-line: <br/>1. Entra en <a href="https://www.servipag.com" target="_blank">Servipag.com</a>.<br/>2. En el buscador, escribe y selecciona "dLocal".<br/>3. Ingresa el número de referencia que figura en este ticket: {number}.<br/>4. Haz click en Ingresar y sigue las instrucciones para el pago.<br/>',
      paymentTypeServipag2:
        'Si es por caja: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Monto",
      orderTips: "Por favor transfiera a la siguiente cuenta",
      numberTitle: "Número de cuenta (solo para esta transacción)",
      copyBtnText: "Copiar número de cuenta",
      expireText: "Pedido expira en {time}",
      bankName: "Nombre del banco",
    },
    date: {
      month: {
        1: "Enero",
        2: "Febrero",
        3: "Marcha",
        4: "Abril",
        5: "Mayo",
        6: "Junio",
        7: "Julio",
        8: "Agosto",
        9: "Septiembre",
        10: "Octubre",
        11: "Noviembre",
        12: "Diciembre",
      },
    },
    result: {
      success: "Pago exitoso",
      failed: "Pago fallido",
      unknow: "Pago",
      unknowTip: "Actualice el estado del pedido para ver el resultado",
      jump: 'Regresando al comerciante en <span class="hightlight">{time}s</span>',
      reason: "Razón",
      processing: "Procesando",
      timeout: "Se agotó el tiempo de espera de la página, reinicie el pago",
      invalid: "Solicitud de transacción no válida",
      cancelled:
        "La solicitud de pago ha sido cancelada,<br/>por favor no repita el pago",
      emptyPaymentMethod: "No hay métodos de pago configurados para este país.",
      error:
        "Error. Verifique el resultado de la transacción en la página de pedido del sitio web de compras.",
    },
    validator: {
      input: "por favor ingrese un {field} valido",
      intallmentCardOrCountryNotSupport:
        "El pago a plazos no es compatible con el tipo de tarjeta o el país",
    },
    error: {
      timeout: "Error de red, inténtalo de nuevo más tarde",
      cardNumber: "La información de la tarjeta es incorrecta",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
