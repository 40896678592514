import ElementUILocal from "element-ui/lib/locale/lang/fi";

import country from "./country/fi";
import state from "./state/fi";

export default {
  message: {
    title: "Kassapiste",
    powerByOnerway: '<span class="ic-powerby"></span> tukema',
    checkout: {
      timeTip:
        'Suorita maksu <span class="time">{time}</span> minuutin kuluessa, muuten sivu aikakatkaistaan',
      billingAddressTip: "Veloitus näkyy laskussasi nimellä {billingAddress}",
      cardInfo: "Korttitiedot",
      cardNumber: "Kortin numero",
      prepaidNumber: "Kortin numero",
      summary: "Tilauksen yhteenveto",
      expiredDate: "Voimassaoloaika",
      issueDate: "Issue date",
      cvv: "CVC/CVV",
      firstName: "Etunimi",
      lastName: "Sukunimi",
      holderName: "Kortinhaltijan nimi",
      walletAccountName: "Nimi",
      orderno: "Tilaus ID",
      website: "Verkkosivusto",
      amount: "Summa",
      pay: "Maksa",
      payNow: "Maksa nyt",
      cancel: "Peruuta",
      tip: "Vinkki",
      cancelPayTip: "Haluatko peruuttaa tämän maksun?",
      yes: "​​Kyllä",
      no: "Ei",
      subscription: "Tilaus",
      schedule: "Maksuaika",
      endTime: "loppu aika",
      subscriptionTip:
        "Maksun vahvistamisen jälkeen annat kauppiaan veloittaa korttiasi ehtojen mukaisesti jatkossa.",
      frequencyData: {
        D: "{time}päivä/aika",
      },
      endTimeData: "{year}vuosi{month}kuukausi{day}päivä",
      billingAddress: "Laskutusosoite/Yhteystiedot",
      country: "Maa",
      province: "Osavaltio/Lääni/Alue",
      address: "Osoite",
      city: "Kaupunki",
      postalCode: "Postinumero",
      email: "Sähköposti",
      phone: "Puhelinnumero",
      street: "Kadun numero",
      number: "Talon numero",
      identityNumber: "Veronumero",
      intallment: "Lavastus",
      intallmentPlan: "Asennussuunnitelma",
      paymentMethod: "Maksutapa",
      bankName: "Pankin nimi",
      iBan: "Pankkitilin numero",
      contactName: "Yhteyshenkilön nimi",
      birthDate: "Syntymäpäivä",
      paymentCompleted: "Maksu suoritettu",
      wechatPaymentTip: "Skannaa QR-koodi WeChatilla maksaaksesi",
      paymentPending: "Odottaa maksua",
      billingCountry: "Laskutusmaa",
      billingCountryTip:
        "Valitse ensin laskutusmaa, jotta voimme suositella sinulle sopivaa maksutapaa.",
      nextStep: "Seuraava askel",
      changeCountryTip:
        "Maanvaihdon jälkeen suosittelemme sinulle sopivia maksutapoja.",
      scanCode: "Skannaa se",
      txnTime: "Kaupan aika",
      moreInfo: "Lisää tietoa",
      applePayNotSupport: "Tämä laite ei tue Apple Payta.",
      policy: {
        prefix: "Olen lukenut ja hyväksyn ",
        suffix: "",
      },
      privacy: "tietosuojakäytännön",
      intallmentTip: "Saatat joutua maksamaan korkoa maksueristä.",
      payInFull: "Maksaa kokonaan",
      payIn: "Maksa {count}:ssä",
      blikcode: "BLIK-koodi",
      unavailable: "Ei saatavilla",
      notSupportPaymentTips:
        "Maksutapa ei ole tällä hetkellä saatavilla. Palaa valintasivulle.",
      returnText: "Palaa valintaan",
      returnMerchant: "Takaisin kauppiaan sivulle",
      cvvTips:
        "CVC-koodi sijaitsee kortin takana valkoisen allekirjoitusnauhan oikealla puolella; se on aina viimeiset 3 numeroa Visassa ja Mastercardissa. American Expressissä se on 4 numeroa kortin etupuolella.",
      holderNameTips: "Kortissa oleva koko nimi",
      continue: "Jatka",
      validateCVVTitle: "Turvallisuuden varmentaminen",
      validateCVVDesc:
        "Maksusi turvallisuuden vuoksi, vahvista korttisi CVC/CVV",
    },
    dlocal: {
      amount: "määrä",
      expirationDate: "Määräaika",
      ticketNumber: "Lipun numero",
      ticketImage: "Lipun kuva",
      downloadTicket: "Lataa lippu",
      searchForShops: "etsiä kauppoja",
      fullBoletoView: "Koko Boleto-näkymä",
      back: "paluu",
      copySuccess: "Kopioi",
      errorMsg: "Parametrien haku epäonnistui.",
      instructionsTitle: "Maksuohje",
      instructionsContentPIX:
        "ID Maksuohjeet:<br/>1. Monistustapahtuman ID<br/>2. Käytä tunnusta PIX-maksujen tunnistamiseen pankkisovelluksessasi tai lue QR-koodi pankin sovellukses",
      instructionsContentRedpagos:
        "Sinun on mentävä redpagos-myymälään maksun suorittamiseksi.<br/>Sanomalla henkilökorttisi numeron ja selventämällä, että haluat maksaa dlocal-laskun, pääset suorittamaan maksun.",
      instructionsContentOXXO:
        "1. Mene lähimpään oxxo-myymälään.<br/>2. Ilmoita ruutuun, että haluat suorittaa oxxopay-maksun.<br/>3. Sanele tässä kortissa oleva viitenumero kassalle kirjoitettavaksi.<br/>Suoraan myyntinäytölle.<br/>4. Suorita vastaava maksu käteisellä.<br/>5. Kun vahvistat maksun, kassa antaa sinulle tulostetun tositteen. Tästä voit tarkistaa, että maksu on tehty oikein. Säilytä tämä maksutosite.<br/>6. Lisätietoja maksusta saat help center -palvelustamme.",
      instructionsContentSPEI:
        "1. Välitön akkreditointi.<br/>2. Kun pankkisiirto on vastaanotettu, dlocal ilmoittaa toimitukselle ostamasi tuotteen/palvelun.<br/>3. Jos sinulla on maksuongelmia, käy asiakaspalveluportaalissamme.",
      instructionsContentSencillito:
        'Maksutoimeksianto henkilökohtaisesti:<br/>Sencilliton toimipaikassa voit suorittaa maksun antamalla viitenumeron ja ilmoittamalla, että haluat maksaa DLOCAL-laskun.<br/>Verkkomaksuohje:<br/>Maksun suorittamiseksi sinun on mentävä Portal Sencillitoon, valittava yrityksen hakukoneessa "dlocal" ja syötettävä kupongissa ilmoitettu koodi 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "Pankkitiedot",
      bankDetailsSPEI:
        'Kirjaudu verkkopankkiin ja siirrä seuraavalle tilille. Huomaa, että käteismaksut eivät ole mahdollisia.<br/>Kohdepankki:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Edunsaaja: <span class="detail-value">{beneficiary}</span><br/>Summa: <span class="detail-value">{amount} {currency}</span><br/>Maksutapa: <span class="detail-value">{concept}</span><br/>Viite: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "Maksun tyyppi",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "Määrä",
      orderTips: "Ole hyvä ja siirrä seuraavalle tilille",
      numberTitle: "Tilinumero (vain tälle tapahtumalle)",
      copyBtnText: "Kopioi tilinumero",
      expireText: "Tilauksen voimassaoloaika päättyy {time}",
      bankName: "Pankin nimi",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "Maksu onnistui",
      failed: "Maksu epäonnistui",
      unknow: "Maksaa",
      unknowTip: "Päivitä tilauksen tila tarkistaaksesi maksutuloksen",
      jump: 'Paluu kauppiaan luo <span class="hightlight">{time}s</span>',
      reason: "Syy",
      processing: "Käsittelyssä...",
      timeout: "Sivun aikakatkaisu, aloita maksu uudelleen",
      invalid: "Virheellinen tapahtumapyyntö",
      cancelled: "Maksuhakemus on peruutettu, älä toista maksua",
      emptyPaymentMethod: "Tälle maalle ei ole määritetty maksutapoja.",
      error: "Virhe. Tarkista tapahtuman tulos ostossivuston tilaussivulta.",
    },
    validator: {
      input: "Anna kelvollinen {field}",
      intallmentCardOrCountryNotSupport:
        "Osamaksua ei tueta korttityypin tai maan mukaan",
    },
    error: {
      timeout: "Verkko on epänormaali, yritä myöhemmin uudelleen",
      cardNumber: "Kortin tiedot ovat virheelliset",
    },
    country,
    state,
  },
  ...ElementUILocal,
};
