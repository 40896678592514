import zhLocale from "element-ui/lib/locale/lang/ar";

import country from "./country/ar";
import state from "./state/ar";

export default {
  message: {
    title: "صندوق الدفع",
    powerByOnerway: 'مدعوم من <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'دقيقة وإلا ستنتهي الصفحة <span class="time">{time}</span> يرجى إكمال الدفع في غضون',
      billingAddressTip: "{billingAddress} ستظهر الرسوم في فاتورتك على أنها",
      cardInfo: "معلومات البطاقة",
      cardNumber: "رقم البطاقة",
      prepaidNumber: "رقم البطاقة",
      summary: "ملخص الطلب",
      expiredDate: "تاريخ الإنتهاء",
      issueDate: "Issue Date",
      cvv: "CVC/CVV",
      firstName: "اسم",
      lastName: "لقب",
      holderName: "إسم صاحب البطاقة",
      walletAccountName: "اسم",
      orderno: "معرّف الطلب",
      website: "URL",
      amount: "مقدار",
      pay: "ادفع",
      payNow: "الدفع على الفور",
      cancel: "يلغي",
      tip: "ملحوظة",
      cancelPayTip: "هل تريد التخلي عن هذا الدفع?",
      yes: "نعم",
      no: "رقم",
      subscription: "اشتراك",
      schedule: "فترة الدفع",
      endTime: "موعد التسليم",
      subscriptionTip:
        "من خلال تأكيد الدفع ، فإنك تسمح للتاجر بالخصم من بطاقتك في المستقبل وفقًا للشروط. ",
      frequencyData: {
        D: "أيام / وقت{time}",
      },
      endTimeData: "{year}عام{month}القمر{day}رقم",
      billingAddress: "عنوان الفواتير / معلومات الاتصال",
      country: "الأمة",
      province: "ولاية / مقاطعة / منطقة",
      address: "عنوان",
      city: "مدينة",
      postalCode: "الرمز البريدي",
      email: "بريد",
      phone: "رقم هاتف",
      street: "رقم الشارع",
      number: "رقم الدار",
      identityNumber: "الرقم الضريبي",
      intallment: "التدريج",
      intallmentPlan: "خطة التقسيط",
      paymentMethod: "طريقة الدفع او السداد",
      bankName: "اسم البنك",
      iBan: "حساب البنك",
      contactName: "اسم جهة الاتصال",
      birthDate: "تاريخ الولادة",
      paymentCompleted: "تم التسديد",
      wechatPaymentTip:
        "الرجاء استخدام WeChat لمسح رمز الاستجابة السريعة للدفع",
      paymentPending: "في انتظار السداد",
      billingCountry: "بلد إرسال الفواتير",
      billingCountryTip:
        "الرجاء تحديد بلد إرسال الفواتير أولاً ، حتى نتمكن من التوصية بطريقة دفع مناسبة لك.",
      nextStep: "الخطوة التالية",
      changeCountryTip: "بعد تبديل البلدان ، نوصيك بطرق الدفع المناسبة.",
      scanCode: "امسحها ضوئيًا",
      txnTime: "وقت المعاملة",
      moreInfo: "معلومات اكثر",
      applePayNotSupport: "هذا الجهاز لا يدعم Apple Pay.",
      policy: {
        prefix: "",
        suffix: "لقد قرأت ووافقت على",
      },
      privacy: "سياسة الخصوصية",
      intallmentTip: "قد تضطر إلى دفع فائدة على الأقساط.",
      payInFull: "الدفع بالكامل",
      payIn: "{count} الدفع في",
      blikcode: "رمز BLIK",
      unavailable: "غير متوفر",
      notSupportPaymentTips:
        "طريقة الدفع غير متاحة في الوقت الحالي. يرجى العودة إلى صفحة الاختيار",
      returnText: "العودة إلى الاختيار",
      returnMerchant: "العودة إلى صفحة التاجر",
      cvvTips:
        "يقع رمز CVC على ظهر بطاقتك على الجانب الأيمن من الشريط الأبيض للتوقيع؛ يكون دائمًا آخر 3 أرقام لبطاقات Visa وMastercard. بالنسبة لـ American Express، يكون 4 أرقام على الجهة الأمامية من البطاقة.",
      holderNameTips: "الاسم الكامل على البطاقة",
      continue: "استمر",
      validateCVVTitle: "التحقق من الأمان",
      validateCVVDesc: "لأمان الدفع الخاص بك، يرجى التحقق من CVC/CVV لبطاقتك",
    },
    dlocal: {
      amount: "المبلغ",
      expirationDate: "الموعد النهائي",
      ticketNumber: "رقم التذكرة",
      ticketImage: "صورة التذكرة",
      downloadTicket: "تنزيل تذكرة",
      searchForShops: "البحث عن المتاجر",
      fullBoletoView: "كامل بوليتو فيو",
      back: "العودة",
      copySuccess: "نسخ",
      errorMsg: "انتهت المهلة. فشل في الحصول على المعلمات.",
      instructionsTitle: "تعليمات الدفع",
      instructionsContentPIX:
        "تعليمات دفع الهوية:<br/>1. معرّف معاملة التكرار<br/>2. استخدم المعرف لتحديد مدفوعات PIX في تطبيقك المصرفي أو اقرأ رمز الاستجابة السريعة على تطبيق البنك.",
      instructionsContentRedpagos:
        "ستحتاج إلى الذهاب إلى متجر ريدباجوس لإجراء عملية الدفع.<br/> من خلال ذكر رقم بطاقة الهوية الخاصة بك وتوضيح أنك تريد دفع فاتورة dlocal، ستتمكن من إجراء عملية الدفع.",
      instructionsContentOXXO:
        "1. اذهب إلى أقرب متجر oxxo.<br/>2. حدد في المربع أنك تريد إجراء عملية دفع oxxopay.<br/>3. قم بإملاء الرقم المرجعي الموجود على هذه البطاقة على أمين الصندوق لكتابته<br/> مباشرة على شاشة المبيعات.<br/>4. قم بالدفع المقابل نقداً.<br/>5. عندما تقوم بتأكيد الدفع، سيعطيك أمين الصندوق قسيمة مطبوعة. هنا يمكنك التحقق من أن عملية الدفع قد تمت بشكل صحيح. احتفظ بإثبات الدفع هذا.<br/>6. لمزيد من المعلومات حول عملية الدفع الخاصة بك قم بزيارة مركز المساعدة الخاص بنا",
      instructionsContentSPEI:
        "1. الاعتماد الفوري.<br/>2. بمجرد استلام التحويل المصرفي، ستقوم شركة dlocal بإبلاغ تسليم المنتج/الخدمة التي تقوم بشرائها.<br/>3. إذا واجهتك أي مشاكل في الدفع، يرجى زيارة بوابة خدمة العملاء.",
      instructionsContentSencillito:
        'تعليمات الدفع شخصيا:<br/> في مكان عمل سينسيليتو، يمكنك الدفع من خلال توفير رقم مرجعي وتوضيح أنك تريد دفع فاتورة Dمحلية.<br/> تعليمات الدفع عبر الإنترنت:<br/> ولدفع المبلغ، يجب إدخال البوابة Sencillito، واختر "dlocal" في محرك بحث الشركة وأدخل الرمز المشار إليه على القسيمة 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: "تفاصيل البنك",
      bankDetailsSPEI:
        'قم بتسجيل الدخول إلى الخدمات المصرفية عبر الإنترنت وقم بالتحويل إلى الحساب التالي. يرجى الملاحظة أنه لا يمكن الدفع نقدا.<br/>بنك الوجهة:<span class="detail-value">{bank}</span><br/>المستفيد: <span class="detail-value">{clabe}</span><br/>المبلغ: <span class="detail-value">{beneficiary}</span><br/>بيزو مكسيكي: <span class="detail-value">{amount} {currency}</span><br/>مفهوم الدفع: <span class="detail-value">{concept}</span><br/>المرجع: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: "نوع الدفع",
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: "المبلغ",
      orderTips: "يرجى التحويل إلى الحساب التالي",
      numberTitle: "رقم الحساب (لهذه المعاملة فقط)",
      copyBtnText: "نسخ رقم الحساب",
      expireText: "الطلب ينتهي في {time}",
      bankName: "اسم البنك",
    },
    date: {
      month: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      },
    },
    result: {
      success: "الدفع بنجاح",
      failed: "عملية الدفع فشلت",
      unknow: "المدفوعات",
      unknowTip: "يرجى تحديث حالة الطلب للتحقق من نتيجة الدفع",
      jump: 'ثوانٍ <span class="hightlight">{time}</span> العودة إلى التاجر في غضون',
      reason: "السبب",
      processing: "معالجة....",
      timeout: "انتهت مهلة الصفحة ، يرجى إعادة بدء الدفع",
      invalid: "طلب معاملة غير صالح",
      cancelled: "تم إلغاء طلب الدفع ، من فضلك لا تدفع مرة أخرى",
      emptyPaymentMethod: "لا توجد طرق دفع مهيأة لهذا البلد.",
      error:
        "خطأ. يرجى التحقق من نتيجة المعاملة على صفحة الطلب في موقع التسوق.",
    },
    validator: {
      input: "الرج {field} إدخال 3 صالح",
      intallmentCardOrCountryNotSupport:
        "الدفع بالتقسيط غير مدعوم حسب نوع البطاقة أو الدولة",
    },
    error: {
      timeout: "خطأ في الشبكة ، يرجى المحاولة مرة أخرى في وقت لاحق",
      cardNumber: "معلومات البطاقة غير صحيحة",
    },
    country,
    state,
  },
  ...zhLocale,
};
